<ion-header>
  <ion-toolbar color="white" class="no-border-toolbar">
    <ion-title>
      <ion-img src="assets/images/c_LOGO_orizzontale_1.png" [style]="'height:40px'"></ion-img>
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div class="ion-no-padding">
    <ion-grid class="ion-no-padding full-h-percent">


      <ion-row class="gap-70px ion-justify-content-center ion-align-items-center">

      </ion-row>

      <ion-row class="ion-padding ion-justify-content-center">
        <ion-col size-sm="12" size-xl="6" size-md="8">

          <ion-card>
            <ion-grid class="full-w-percent">
              <ion-row>
                <ion-col>
                  <ion-item class="item-box">
                    <ion-label position="floating">E-mail o username</ion-label>
                    <ion-input [formControl]="ctrlEmail()" [placeholder]="'Inserisci e-mail o username'" type="email"></ion-input>
                    <ion-icon name="person-circle-outline" slot="start" color="primary"></ion-icon>
                    <ion-note *ngIf="ctrlEmail().touched" slot="error">Campo obbligatorio</ion-note>
                  </ion-item>

                  <ion-item class="item-box" [style]="'margin-top: 10px;'">
                    <ion-label position="floating">Password</ion-label>
                    <ion-input [formControl]="ctrlPassword()" [placeholder]="'Inserisci password'"
                               type="password" #passwordField></ion-input>
                    <ion-icon name="key-outline" slot="start" color="tertiary"></ion-icon>
                    <ion-buttons slot="end" style="display: block; margin: auto">
                      <ion-button
                        (click)="passwordField.type === 'password' ? passwordField.type = 'text' : passwordField.type = 'password'">
                        <ion-icon *ngIf="passwordField.type === 'password'" slot="icon-only" name="eye-off-outline"
                                  color="tertiary"></ion-icon>
                        <ion-icon *ngIf="passwordField.type === 'text'" slot="icon-only" name="eye-outline"
                                  color="tertiary"></ion-icon>
                      </ion-button>
                    </ion-buttons>
                  </ion-item>

                  <ion-grid class="full-w-percent">
                    <ion-row class="ion-justify-content-center ion-align-items-center">
                      <ion-col class="full-w-percent">
                        <ion-row class="ion-justify-content-center ion-padding-top ion-padding-bottom">
                          <ion-label (click)="recoverPassword()" class="cursor-pointer fs-pt-12"><u>Password
                            dimenticata?</u></ion-label>
                        </ion-row>
                        <ion-row class="ion-justify-content-center">
                          <ion-button [disabled]="loginForm.invalid" color="primary" (click)="signIn()"
                                      class="full-w-percent">Accedi
                          </ion-button>
                        </ion-row>
                        <ion-row class="ion-justify-content-center ion-padding-top ion-padding-bottom">
                          <div style="text-align: center; display: flex; flex-direction: column">
                            <ion-label class="fs-pt-12">Non hai le credenziali di accesso?</ion-label>
                            <ion-label (click)="register()" class="cursor-pointer mt-0_25 fs-pt-12"><a>Registrati</a>
                            </ion-label>
                          </div>
                        </ion-row>
                      </ion-col>
                    </ion-row>
                  </ion-grid>
                </ion-col>

              </ion-row>
            </ion-grid>
          </ion-card>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
</ion-content>
