import {Component, OnInit} from '@angular/core';
import {LocalStorageService} from "../../services/local-storage.service";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {NavController} from "@ionic/angular";

import {NotificationService} from "../../services/notification.service";
import {
  atLeastEightCharacters,
  atLeastOneLetter,
  atLeastOneNumber,
  atLeastOneSpecialCharacter,
  emailRegex,
  passwordRegex
} from "../../shared/constants/regex";
import {catchError, finalize, from, switchMap, tap, timeout} from "rxjs";
import {HttpErrorResponse} from "@angular/common/http";
import {compareValidator} from "../../shared/utils/validators";
import {get} from "lodash";
import {ModalConfiguration} from "../../shared/interfaces/modal-configuration-interface";
import {LoaderService} from "../../services/loader.service";
import {ManageErrorService, RequestTypesEnum} from "../../services/manage-error.service";
import {PathEnum} from "../../shared/enums/path-enum";
import {AuthenticationService} from "../../services/authentication.service";
import {AddUserDTO, UserControllerService} from "../../../api-clients/generated/services";
import {Auth} from "../../shared/interfaces/auth";
import {NetworkService} from "../../services/network.service";

@Component({
  selector: 'app-register',
  templateUrl: './register.page.html',
  styleUrls: ['./register.page.scss'],
})
export class RegisterPage implements OnInit {

  private currentRequest: RequestTypesEnum = RequestTypesEnum.REGISTRAZIONE;
  registerForm: FormGroup;
  successMessage: string = 'Registrazione avvenuta con successo';
  successMessageOnLoginError: string = 'Registrazione avvenuta con successo. Si prega di effettuare la login';
  invalidMessage: string = 'Ci sono errori nei dati inseriti. Si prega di compilare tutti i campi correttamente e riprovare'
  offlineErrorMessage = 'Attenzione, connessione scarsa o assente. Impossibile inviare la richiesta.'
  showErrorsNote = false;
  oneLetter: any | boolean | undefined;
  oneNumber: any | boolean | undefined;
  oneSpecial: any | boolean | undefined;
  eightCharacters: any | boolean | undefined;

  constructor(private localStorageService: LocalStorageService,
              private fb: FormBuilder,
              public navCtrl: NavController,
              private authenticationService: AuthenticationService,
              private notificationService: NotificationService,
              private userControllerService: UserControllerService,
              private manageErrorService: ManageErrorService,
              private loaderService: LoaderService,
              private networkService: NetworkService) {
    this.registerForm = this.fb.group(
      {
        firstName: [null, Validators.required],
        lastName: [null, Validators.required],
        email: [null, [Validators.required, Validators.pattern(new RegExp(emailRegex))]],
        password: [null, [Validators.required, Validators.pattern(new RegExp(passwordRegex))]],
        confirm_password: [null, Validators.required],
        conditionsTerminiServizio: [false, Validators.requiredTrue],
        trattamentoDatiFunzionamento: [false, Validators.requiredTrue],
        trattamentoDatiStatistica: [false, Validators.requiredTrue],
        trattamentoDatiComunicazione: [false, Validators.requiredTrue],
        trattamentoDatiRicezione: [false],
        trattamentoDatiEsperienza: [false],
      },
      {
        validators: [
          compareValidator('password', 'confirm_password'),
        ]
      }
    )
  }

  ngOnInit() {
    this.ctrlCheckToggle.disable({emitEvent: false});
    this.ctrlTrattamentoDatiEsperienza.disable({emitEvent: false});
    this.ctrlTrattamentoDatiComunicazione.disable({emitEvent: false});
    this.ctrlTrattamentoDatiRicezione.disable({emitEvent: false});
    this.ctrltrattamentoDatiStatistica.disable({emitEvent: false});
    this.ctrlTrattamentoDatiFunzionamento.disable({emitEvent: false});

    this.ctrlPassword.valueChanges.pipe(
      tap((value) => {
        this.checkRegexMatch(value)
      })
    ).subscribe()
  }

  get ctrlEmail() {
    return this.registerForm.get('email') as FormControl;
  }

  get ctrlPassword() {
    return this.registerForm.get('password') as FormControl;
  }

  get ctrlName() {
    return this.registerForm.get('firstName') as FormControl;
  }

  get ctrlSurname() {
    return this.registerForm.get('lastName') as FormControl;
  }

  get ctrlConfirmPassword() {
    return this.registerForm.get('confirm_password') as FormControl;
  }

  get ctrlCheckToggle() {
    return this.registerForm.get('conditionsTerminiServizio') as FormControl;
  }

  get ctrlTrattamentoDatiFunzionamento() {
    return this.registerForm.get('trattamentoDatiFunzionamento') as FormControl;
  }

  get ctrltrattamentoDatiStatistica() {
    return this.registerForm.get('trattamentoDatiStatistica') as FormControl;
  }

  get ctrlTrattamentoDatiComunicazione() {
    return this.registerForm.get('trattamentoDatiComunicazione') as FormControl;
  }

  get ctrlTrattamentoDatiRicezione() {
    return this.registerForm.get('trattamentoDatiRicezione') as FormControl;
  }

  get ctrlTrattamentoDatiEsperienza() {
    return this.registerForm.get('trattamentoDatiEsperienza') as FormControl;
  }


  register() {
    this.showErrorsNote = true;
    this.registerForm.markAllAsTouched();
    if (this.registerForm.valid && !!this.ctrlCheckToggle.value
      && !!this.ctrlTrattamentoDatiFunzionamento.value
      && !!this.ctrltrattamentoDatiStatistica.value
      && !!this.ctrlTrattamentoDatiComunicazione.value) {
      this.registerUserAndLogin();
    } else {
      this.registerForm.markAllAsTouched()
      this.notificationService.openToast({message: this.invalidMessage, color: 'danger'});
    }
  }

  registerUserAndLogin() {
    const login$ = this.authenticationService.login(
      this.ctrlEmail.value, this.ctrlPassword.value
    ).pipe(
      //tap((response: Auth) => this.localStorageService.setAuth(response)),
      switchMap((response: Auth) => this.localStorageService.setAuth$(response)),
      switchMap(() => this.setProfileObservable())
    );

    this.currentRequest = RequestTypesEnum.REGISTRAZIONE
    this.loaderService.showLoaderObserve$().pipe(
      switchMap(() => this.networkService.getNetworkStatus$()),
      timeout(5000),
      switchMap(() => this.userControllerService.addUser(this.getRegisterRequestBody())),
      switchMap(() => {
        this.currentRequest = RequestTypesEnum.LOGIN;
        return login$;
      }),
      finalize(() => this.loaderService.hideLoader())
    ).subscribe({
      next: value => {
        this.notificationService.openToast({message: this.successMessage, color: 'success'})
        this.navCtrl.navigateRoot(PathEnum.HOME);
      },
      error: err => {
        this.loaderService.hideLoader();
        if (err.name === RequestTypesEnum.TIMEOUT || err.status === 504 || !this.networkService.networkStatus?.connected) {
          this.notificationService.openToast({message: this.offlineErrorMessage, color: 'danger'});
        } else if (this.currentRequest === RequestTypesEnum.LOGIN) {
          this.notificationService.openToast({message: this.successMessageOnLoginError, color: 'success'})
          this.navCtrl.navigateRoot(PathEnum.LOGIN);
        } else {
          this.manageErrorService.manageError(err, this.currentRequest);
        }
      }
    })
  }

  setProfileObservable() {
    return this.userControllerService.getUser().pipe(
      // tap((user) => this.localStorageService.setProfile(user))
      switchMap((user) => this.localStorageService.setPreferenceProfile$(user))
    )
  }

  getRegisterRequestBody() {
    return {
      username: this.ctrlEmail.value,
      email: this.ctrlEmail.value,
      password: this.ctrlPassword.value,
      firstName: this.ctrlName.value,
      lastName: this.ctrlSurname.value,
      conditionsTerminiServizio: this.ctrlCheckToggle.value,
      conditionsDatiFunzionamento: this.ctrlTrattamentoDatiFunzionamento.value,
      conditionsDatiElaborazioneStatistica: this.ctrltrattamentoDatiStatistica.value,
      conditionsDatiFinalitaComunicazione: this.ctrlTrattamentoDatiComunicazione.value,
      conditionsDatiFinalitaMessaggi: this.ctrlTrattamentoDatiRicezione.value,
      conditionsDatiFinalitaMiglioramento: this.ctrlTrattamentoDatiEsperienza.value,
    }
  }

  openTermsAndConditions() {
    const acceptTermsAndConditionsConfiguration: ModalConfiguration = {
      form: {
        accept_terms_and_conditions: [true]
      },
      text: {
        modal_title: 'Termini e condizioni',
        confirm_button: 'Chiudi',
      },
      conditions: {
        confirm_button: true,
        cancel_button: false,
        terms_conditions: true,
        hidden_form: true
      },
      cssClass: 'termini-condizioni-modal'
    }
    from(this.notificationService.openModal(acceptTermsAndConditionsConfiguration)).pipe(
      tap((form) => {
          if (!!get(form, ['accept_terms_and_conditions'])) {
            this.ctrlCheckToggle.enable();
            this.ctrlTrattamentoDatiEsperienza.enable();
            this.ctrlTrattamentoDatiComunicazione.enable();
            this.ctrlTrattamentoDatiRicezione.enable();
            this.ctrltrattamentoDatiStatistica.enable();
            this.ctrlTrattamentoDatiFunzionamento.enable();
          }
        }
      ),
    ).subscribe();
  }

  goToLogin() {
    this.navCtrl.navigateRoot(PathEnum.LOGIN);
  }

  checkRegexMatch(value: string) {
    const regex1 = new RegExp(atLeastOneNumber);
    const regex2 = new RegExp(atLeastOneLetter);
    const regex3 = new RegExp(atLeastOneSpecialCharacter);
    const regex4 = new RegExp(atLeastEightCharacters);
    this.oneLetter = (regex2.exec(value));
    this.oneNumber = (regex1.exec(value) );
    this.oneSpecial = (regex3.exec(value) );
    this.eightCharacters = (regex4.exec(value));
  }

  nascondiBottone(): boolean {
    return !(this.ctrlCheckToggle.value &&
      this.ctrlTrattamentoDatiComunicazione.value &&
      this.ctrltrattamentoDatiStatistica.value &&
      this.ctrlTrattamentoDatiFunzionamento.value);
  }
}
