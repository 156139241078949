import {Component, OnInit} from '@angular/core';
import {LocalStorageService} from "../../services/local-storage.service";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {ModalController, NavController} from "@ionic/angular";
import {emailRegex} from "../../shared/constants/regex";
import {LoaderService} from "../../services/loader.service";
import {catchError, finalize, switchMap, tap, throwError, timeout} from "rxjs";
import {NotificationService} from "../../services/notification.service";
import {PathEnum} from "../../shared/enums/path-enum";
import {ModalConfiguration} from "../../shared/interfaces/modal-configuration-interface";
import {SamlAuthService} from "../../services/saml-auth.service";
import {ManageErrorService, RequestTypesEnum} from "../../services/manage-error.service";
import {AuthenticationService} from "../../services/authentication.service";
import {Auth} from "../../shared/interfaces/auth";
import {UserControllerService} from "../../../api-clients/generated/services";
import {HttpErrorResponse} from "@angular/common/http";
import {NetworkService} from "../../services/network.service";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  offlineErrorMessage = 'Attenzione, connessione scarsa o assente. Impossibile inviare la richiesta.'

  constructor(private localStorageService: LocalStorageService,
              private formBuilder: FormBuilder,
              public navCtrl: NavController,
              private loaderService: LoaderService,
              private notificationService: NotificationService,
              private samlAuthService: SamlAuthService,
              private manageErrorService: ManageErrorService,
              private authenticationService: AuthenticationService,
              private userControllerService: UserControllerService,
              private modalController: ModalController,
              private networkService: NetworkService) {
    this.loginForm = this.formBuilder.group(
      {
        email: [null, Validators.required],
        password: [null, Validators.required]
      }
    )
  }

  ngOnInit() {
  }

  ctrlEmail() {
    return this.loginForm.get('email') as FormControl;
  }

  ctrlPassword() {
    return this.loginForm.get('password') as FormControl;
  }

  setProfileObservable() {
    return this.userControllerService.getUser().pipe(
      /*tap((user) => {
        this.localStorageService.setProfile(user);
      })*/
      switchMap((user) => this.localStorageService.setPreferenceProfile$(user))
    )
  }

  register() {
    this.navCtrl.navigateRoot(PathEnum.REGISTER);
  }

  recoverPassword() {
    this.openModal();
  }

  openModal() {
    const recoverPasswordConfiguration: ModalConfiguration = {
      form: {
        'e-mail': [null, [Validators.required, Validators.pattern(new RegExp(emailRegex))]]
      },
      text: {
        modal_title: 'Recupera Password',
        confirm_button: 'Recupera Password',
        cancel_button: 'Annulla'
      },
      functions: {
        confirm_function: (form) => this.modalConfirm(form),
      },
      cssClass: 'generic-modal'
    }
    this.notificationService.openModal(recoverPasswordConfiguration);
  }

  modalConfirm(form: FormGroup) {
    this.loaderService.showLoaderObserve$().pipe(
      switchMap(() => this.userControllerService.resetUserPassword(form.value!['e-mail'])),
      finalize(() => this.loaderService.hideLoader())
    ).subscribe({
      next: value => {
        this.notificationService.openToast({message: 'Richiesta inviata con successo', color: 'success'});
        return this.modalController.dismiss()
      },
      error: (err: HttpErrorResponse) => {
        this.manageErrorService.manageError(err, RequestTypesEnum.ERRORE_RECUPERA_PASSWORD);
      }
    })
  }

  signIn(): void {
    this.loaderService.showLoaderObserve$().pipe(
      switchMap(() => this.networkService.getNetworkStatus$()),
      timeout(5000),
      switchMap(() => this.authenticationService.login(this.ctrlEmail().value, this.ctrlPassword().value)),
      switchMap((value: Auth) => this.localStorageService.setAuth$(value)),
      //switchMap(() => this.setProfileObservable()),
      finalize(() => this.loaderService.hideLoader())
    ).subscribe({
      next: value => {
        this.navCtrl.navigateRoot(PathEnum.HOME);
      },
      error: err => {
        if (err.name === RequestTypesEnum.TIMEOUT || err.status === 504 || !this.networkService.networkStatus?.connected) {
          this.notificationService.openToast({message: this.offlineErrorMessage, color: 'danger'});
        } else {
          this.manageErrorService.manageError(err, RequestTypesEnum.LOGIN);
        }
      }
    });
  }

}
