import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {LocalStorageService} from './local-storage.service';
import {CustomHttpUrlEncodingCodec} from './custom-http-url-encoding.codec';

@Injectable({
  providedIn: 'root'
})
export class SamlAuthService {

  constructor(protected httpClient: HttpClient,
              private localStorageService: LocalStorageService) {
  }

  signIn(username: string, password: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    const loginPath = 'https://auth.dev.obiettivotev.it/auth/realms/talk-realm/protocol/openid-connect/token';
    const headers = new HttpHeaders();
    let formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
    formParams = formParams.append('email', <any>username) as any || formParams;
    formParams = formParams.append('password', <any>password) as any || formParams;
    return this.httpClient.request<any>('post', `${loginPath}`,
      {
        body: formParams,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }


  postRequest(inputCode: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    const basePath = 'https://dev-keycloak.southengineering.it';
    const headers = new HttpHeaders();
    const consumes: string[] = [
      'application/x-www-form-urlencoded'
    ];

    const canConsumeForm = this.canConsumeForm(consumes);

    let formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});

    //const redirectUri = 'http://localhost:4200/sign-in';
    const redirectUri = 'https://shibboleth-poc.southengineering.it/sign-in';

    formParams = formParams.append('client_id', 'samltest-cli');
    formParams = formParams.append('grant_type', 'authorization_code');
    formParams = formParams.append('client_secret', 'EpXbEWwVCZHKw7m1Y2AEYLbtBEBYlNVe');
    formParams = formParams.append('code', inputCode);
    formParams = formParams.append('redirect_uri', redirectUri);

    return this.httpClient.request<any>('post', `${basePath}/auth/realms/samltest-realm/protocol/openid-connect/token`,
      {
        body: formParams,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  postRequestRerfresh(refresh_token: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    const basePath = 'https://dev-keycloak.southengineering.it';
    const headers = new HttpHeaders();
    const consumes: string[] = [
      'application/x-www-form-urlencoded'
    ];

    const canConsumeForm = this.canConsumeForm(consumes);

    let formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});

    formParams = formParams.append('client_id', 'samltest-cli');
    formParams = formParams.append('grant_type', 'refresh_token');
    formParams = formParams.append('client_secret', 'EpXbEWwVCZHKw7m1Y2AEYLbtBEBYlNVe');
    formParams = formParams.append('refresh_token', refresh_token);

    return this.httpClient.request<any>('post', `${basePath}/auth/realms/samltest-realm/protocol/openid-connect/token`,
      {
        body: formParams,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  getProfileRequest(access_token: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    const basePath = 'https://shibboleth-poc.southengineering.it';
    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'application/json');
    headers = headers.set('Authorization', 'Bearer ' + access_token);

    return this.httpClient.request<any>('get', `${basePath}/shibboleth-poc/users/profile`,
      {
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  getAllProfilesRequest(access_token: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    const basePath = 'https://shibboleth-poc.southengineering.it';
    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'application/json');
    headers = headers.set('Authorization', 'Bearer ' + access_token);

    return this.httpClient.request<any>('get', `${basePath}/shibboleth-poc/users`,
      {
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }
}

export interface TokenResponse {
  access_token: string;
  expires_in: number;
  id_token: string;
  'not-before-policy': number;
  refresh_expires_in: number;
  refresh_token: string;
  scope: string;
  session_state: string;
  token_type: string;
}

export interface UserData {
  created: string;
  email: string;
  enabled: boolean;
  firstName: string;
  grants: any;
  id: string;
  lastName: string;
  username: string;
}
