import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import {PathEnum} from "./shared/enums/path-enum";
import {AuthGuard} from "./guard/auth.guard";
import {NoAuthGuard} from "./guard/noAuth.guard";

const routes: Routes = [

  {
    canActivate: [AuthGuard],
    path: 'pages',
    loadChildren: () => import('./pages/pages.module').then( m => m.PagesModule),
  },
  {
    canActivate: [NoAuthGuard],
    path: 'update-password',
    loadChildren: () => import('./pages/recover-password/recover-password.module').then( m => m.RecoverPasswordPageModule)
  },
  {
    canActivate: [NoAuthGuard],
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginModule)
  },
  {
    canActivate: [NoAuthGuard],
    path: 'register',
    loadChildren: () => import('./pages/register/register.module').then( m => m.RegisterPageModule)
  },
  {
    path: '',
    redirectTo: PathEnum.LOGIN,
    pathMatch: 'full'
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
