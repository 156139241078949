import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {emailRegex, passwordRegex} from "../../shared/constants/regex";
import {compareValidator} from "../../shared/utils/validators";
import {ActivatedRoute, Router} from "@angular/router";
import {finalize, switchMap, tap, timeout} from "rxjs";
import {PathEnum} from "../../shared/enums/path-enum";
import {NotificationService} from "../../services/notification.service";
import {NavController} from "@ionic/angular";
import {UserControllerService} from "../../../api-clients/generated/services";
import {ManageErrorService, RequestTypesEnum} from "../../services/manage-error.service";
import {LoaderService} from "../../services/loader.service";
import {NetworkService} from "../../services/network.service";

@Component({
  selector: 'app-recover-password',
  templateUrl: './recover-password.page.html',
  styleUrls: ['./recover-password.page.scss'],
})
export class RecoverPasswordPage implements OnInit {
  recoverPasswordForm: FormGroup;
  recoverPasswordToken: string = '';
  offlineErrorMessage = 'Attenzione, connessione scarsa o assente. Impossibile inviare la richiesta.'


  constructor(private fb: FormBuilder,
              private router: Router,
              private route: ActivatedRoute,
              private userControllerService: UserControllerService,
              private notificationService: NotificationService,
              private navCtrl: NavController,
              private manageErrorService: ManageErrorService,
              private loaderService: LoaderService,
              private networkService: NetworkService) {
    this.recoverPasswordForm = this.fb.group(
      {
        password: [null, [Validators.required, Validators.pattern(new RegExp(passwordRegex))]],
        confirm_password: [null, Validators.required],
      },
      {validators: [compareValidator('password', 'confirm_password')]}
    )
  }

  ngOnInit() {
  }

  get ctrlPassword() {
    return this.recoverPasswordForm.get('password') as FormControl;
  }

  get ctrlConfirmPassword() {
    return this.recoverPasswordForm.get('confirm_password') as FormControl;
  }

  changePassword() {
    this.loaderService.showLoaderObserve$().pipe(
      switchMap(() => this.networkService.getNetworkStatus$()),
      timeout(5000),
      switchMap(() => this.route.queryParams),
      tap((params) => this.recoverPasswordToken = params['resetPwdToken']),
      switchMap(() =>
        this.userControllerService.updateUserPassword(this.ctrlPassword.value, this.recoverPasswordToken)),
      finalize(() => this.loaderService.hideLoader()),
    ).subscribe({
      next: value => {
        this.loaderService.hideLoader();
        this.notificationService.openToast({message: 'Nuova password settata con successo', color: 'success'});
        this.navCtrl.navigateRoot(PathEnum.LOGIN, {replaceUrl: true});
      },
      error: err => {
        if (err.name === RequestTypesEnum.TIMEOUT || err.status === 504 || !this.networkService.networkStatus?.connected) {
          this.notificationService.openToast({message: this.offlineErrorMessage, color: 'danger'});
        } else {
          this.manageErrorService.manageError(err, RequestTypesEnum.UPDATE_PASSWORD)
        }
      }
    })
  }

}

