import { Injectable } from '@angular/core';
import {LoadingController, LoadingOptions} from "@ionic/angular";
import {from} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  loading: HTMLIonLoadingElement | undefined;
  private isLoading = false;

  constructor(
    private loadingCtrl: LoadingController
  ) { }

  async showLoader() {
    if(!this.isLoading) {
      this.isLoading = true;
      this.loading = await this.loadingCtrl.create({
        spinner: 'bubbles',
        cssClass: 'loader'
      });
      return this.loading.present();
    }
    return new Promise<boolean>((resolve) => resolve(true));
  }


  hideLoader() {
    this.loading?.dismiss();
    this.isLoading = false;
  }

  showLoaderObserve$(){
    return from(this.showLoader());
  }
}
