<ion-header>
  <ion-toolbar color="white" class="no-border-toolbar ">
    <ion-title>
      <ion-img src="assets/images/c_LOGO_orizzontale_1.png" [style]="'height:40px'"></ion-img>
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div class="ion-padding">
    <ion-grid class="ion-no-padding full-h-percent">
      <ion-row class="ion-justify-content-center full-h-percent">
        <ion-col size-sm="12" size-xl="6" size-md="8" class="ion-no-padding">
          <ion-card>
            <ion-grid class="full-w-percent">
              <ion-row>
                <ion-col>
                  <!--ITEMS-->

                  <ion-item class="item-box mt-px-10">
                    <ion-label position="floating">Nome</ion-label>
                    <ion-input [formControl]="ctrlName" [placeholder]="'Inserisci nome'" type="text"></ion-input>
                    <ion-note *ngIf="ctrlName.touched" slot="error">Nome obbligatorio</ion-note>
                  </ion-item>

                  <ion-item class="item-box mt-px-10">
                    <ion-label position="floating">Cognome</ion-label>
                    <ion-input [formControl]="ctrlSurname" [placeholder]="'Inserisci cognome'"
                               type="email"></ion-input>
                    <ion-note *ngIf="ctrlSurname.touched" slot="error">Cognome obbligatorio</ion-note>
                  </ion-item>

                  <ion-item class="item-box mt-px-10">
                    <ion-label position="floating">E-mail</ion-label>
                    <ion-input [formControl]="ctrlEmail" type="email"></ion-input>
                    <ion-note *ngIf="ctrlEmail.touched" slot="error">E-mail non valida</ion-note>
                  </ion-item>


                  <ion-item class="item-box mt-px-10">
                    <ion-label position="floating">Password</ion-label>
                    <ion-input [formControl]="ctrlPassword" [placeholder]="'Inserisci password'"
                               type="password" #passwordField></ion-input>
                    <ion-buttons slot="end" style="display: block; margin: auto">
                      <ion-button
                        (click)="passwordField.type === 'password' ? passwordField.type = 'text' : passwordField.type = 'password'">
                        <ion-icon *ngIf="passwordField.type === 'password'" slot="icon-only" name="eye-off-outline"
                                  color="tertiary"></ion-icon>
                        <ion-icon *ngIf="passwordField.type === 'text'" slot="icon-only" name="eye-outline"
                                  color="tertiary"></ion-icon>
                      </ion-button>
                    </ion-buttons>
                    <ion-note *ngIf="ctrlPassword.touched && !ctrlPassword.hasError('pattern')"
                              slot="error">Password obbligatoria
                    </ion-note>
                  </ion-item>

                  <br>
                  <ion-label class="ion-padding-start">La password deve contenere:</ion-label>
                  <ion-list>
                    <ion-item class="fs-14-px" color="">
                      <ion-icon name="radio-button-on-outline" slot="start"
                                [color]="eightCharacters ? 'success' : 'danger'"></ion-icon>
                      Almeno 8 caratteri.
                    </ion-item>
                    <ion-item class="fs-14-px">
                      <ion-icon name="radio-button-on-outline" slot="start"
                                [color]="oneNumber ? 'success' : 'danger'"></ion-icon>
                      Almeno un numero
                    </ion-item>
                    <ion-item class="fs-14-px">
                      <ion-icon name="radio-button-on-outline" slot="start"
                                [color]="oneLetter ? 'success' : 'danger'"></ion-icon>
                      Almeno una lettera
                    </ion-item>
                    <ion-item class="fs-14-px">
                      <ion-icon name="radio-button-on-outline" slot="start"
                                [color]="oneSpecial ? 'success' : 'danger'"></ion-icon>
                      Almeno uno di questi caratteri speciali: !@#$%.,^&*
                    </ion-item>
                  </ion-list>

                  <ion-item class="item-box mt-px-10">
                    <ion-label position="floating">Conferma password</ion-label>
                    <ion-input [formControl]="ctrlConfirmPassword" [placeholder]="'Inserisci di nuovo la password'"
                               type="password" #confirmPasswordField></ion-input>
                    <ion-buttons slot="end" style="display: block; margin: auto">
                      <ion-button
                        (click)="confirmPasswordField.type === 'password' ? confirmPasswordField.type = 'text' : confirmPasswordField.type = 'password'">
                        <ion-icon *ngIf="confirmPasswordField.type === 'password'" slot="icon-only"
                                  name="eye-off-outline"
                                  color="tertiary"></ion-icon>
                        <ion-icon *ngIf="confirmPasswordField.type === 'text'" slot="icon-only" name="eye-outline"
                                  color="tertiary"></ion-icon>
                      </ion-button>
                    </ion-buttons>
                    <ion-note *ngIf="ctrlConfirmPassword.touched && ctrlConfirmPassword.hasError('required')"
                              slot="error">Conferma password obbligatoria
                    </ion-note>
                    <ion-note
                      *ngIf="!ctrlConfirmPassword.hasError('required') && ctrlConfirmPassword.hasError('mustMatch')"
                      slot="error">Le password non combaciano
                    </ion-note>
                  </ion-item>

                  <!-- BUTTONS -->
                  <ion-grid>
                    <div class="ion-padding">
                      <ion-label>
                        Le chiediamo di leggere e prestare il Suo consenso
                        <u (click)="openTermsAndConditions()" class="cursor-pointer">
                          <a>ai termini del Servizio e al trattamento dei dati</a></u> da
                        parte nostra, assicurandole il nostro massimo impegno
                      </ion-label>
                    </div>

                    <ion-row class="ion-justify-content-center ion-align-items-center"
                             *ngIf="nascondiBottone()">
                      <ion-col size-sm="12" size-xl="6" size-md="8">
                        <ion-row class="ion-justify-content-center">
                          <ion-button color="primary" (click)="openTermsAndConditions()" class="full-w-percent">
                            Visualizza per procedere
                          </ion-button>
                        </ion-row>
                      </ion-col>
                    </ion-row>

                    <!---MARK: TERMINI SERVIZIO --->
                    <ion-row class="ion-justify-content-start ion-align-items-center">
                      <ion-item lines="none">
                        <ion-checkbox [checked]="false" [formControl]="ctrlCheckToggle"></ion-checkbox>
                        <ion-label>
                          Ho letto e accetto i termini del Servizio
                          <ion-text color="danger">*</ion-text>
                        </ion-label>
                      </ion-item>
                    </ion-row>

                    <ion-row class="ion-justify-content-start ion-align-items-center">
                      <ion-note class="pl-20-px"
                                *ngIf="!ctrlCheckToggle.value && showErrorsNote" color="danger">
                        Il campo accettazione dei termini di servizio è richiesto.
                      </ion-note>
                    </ion-row>
                    <!---ENDMARK: TERMINI SERVIZIO --->

                    <!---MARK: FUNZIONAMENTO SERVIZIO --->
                    <ion-row class="ion-justify-content-start ion-align-items-center">
                      <ion-item lines="none">
                        <ion-checkbox [formControl]="ctrlTrattamentoDatiFunzionamento"></ion-checkbox>
                        <ion-label>
                          Ho letto e accetto il trattamento dei miei dati per finalità di funzionamento del Servizio,
                          delle app e assistenza
                          <ion-text color="danger">*</ion-text>
                        </ion-label>
                      </ion-item>
                    </ion-row>

                    <ion-row class="ion-justify-content-start ion-align-items-center">
                      <ion-note class="pl-20-px"
                                *ngIf="!ctrlTrattamentoDatiFunzionamento.value && showErrorsNote"
                                color="danger">
                        Il campo accettazione del trattamento dati per funzionamento è richiesto.
                      </ion-note>
                    </ion-row>
                    <!---ENDMARK: FUNZIONAMENTO SERVIZIO --->

                    <!---MARK: ELABORAZIONE STATISTICA --->
                    <ion-row class="ion-justify-content-start ion-align-items-center">
                      <ion-item lines="none">
                        <ion-checkbox [formControl]="ctrltrattamentoDatiStatistica"></ion-checkbox>
                        <ion-label>
                          Ho letto e accetto il trattamento dei miei dati per finalità di elaborazione statistica
                          <ion-text color="danger">*</ion-text>
                        </ion-label>
                      </ion-item>
                    </ion-row>

                    <ion-row class="ion-justify-content-start ion-align-items-center">
                      <ion-note class="pl-20-px"
                                *ngIf="!ctrltrattamentoDatiStatistica.value && showErrorsNote"
                                color="danger">
                        Il campo accettazione del trattamento dati per finalità di elaborazione statistica è richiesto.
                      </ion-note>
                    </ion-row>
                    <!---ENDMARK: ELABORAZIONE STATISTICA --->

                    <!---MARK: COMUNICAZIONE DIRETTA --->
                    <ion-row class="ion-justify-content-start ion-align-items-center">
                      <ion-item lines="none">
                        <ion-checkbox [formControl]="ctrlTrattamentoDatiComunicazione"></ion-checkbox>
                        <ion-label>
                          Ho letto e accetto il trattamento dei miei dati per finalità di comunicazione diretta da
                          HEALTH&LIFE (editore del Servizio)
                          <ion-text color="danger">*</ion-text>
                        </ion-label>
                      </ion-item>
                    </ion-row>

                    <ion-row class="ion-justify-content-start ion-align-items-center">
                      <ion-note class="pl-20-px"
                                *ngIf="!ctrlTrattamentoDatiComunicazione.value && showErrorsNote"
                                color="danger">
                        Il campo accettazione del trattamento dati per finalità di comunicazione diretta è richiesto.
                      </ion-note>
                    </ion-row>
                    <!---ENDMARK: COMUNICAZIONE DIRETTA --->

                    <ion-row class="ion-justify-content-start ion-align-items-center">
                      <ion-item lines="none">
                        <ion-checkbox [checked]="false" [formControl]="ctrlTrattamentoDatiRicezione"></ion-checkbox>
                        <ion-label>Ho letto e accetto il trattamento dei miei dati per finalità di ricezione di messaggi
                          su prodotti e attività di sponsor e partner di HEALTH&LIFE.
                        </ion-label>
                      </ion-item>
                    </ion-row>

                    <ion-row class="ion-justify-content-start ion-align-items-center">
                      <ion-item lines="none">
                        <ion-checkbox [checked]="false" [formControl]="ctrlTrattamentoDatiEsperienza"></ion-checkbox>
                        <ion-label>Ho letto e accetto il trattamento dei miei dati per finalità di miglioramento
                          dell’esperienza di utilizzo
                        </ion-label>
                      </ion-item>
                    </ion-row>

                    <ion-row class="ion-justify-content-center ion-align-items-center ion-margin-top full-w-percent">
                      <ion-col>
                        <ion-row class="ion-justify-content-center">
                          <ion-button
                            color="primary" (click)="register()" class="full-w-percent">Registrati
                          </ion-button>
                        </ion-row>
                        <ion-row class="ion-justify-content-center ion-padding-top ion-padding-bottom">
                          <div style="text-align: center; display: flex; flex-direction: column">
                            <ion-label class="fs-pt-12">Hai già un account?</ion-label>
                            <ion-label (click)="goToLogin()" class="cursor-pointer mt-0_25 fs-pt-12"><a>Vai alla
                              login</a>
                            </ion-label>
                          </div>
                        </ion-row>
                      </ion-col>
                    </ion-row>
                  </ion-grid>
                </ion-col>
              </ion-row>
            </ion-grid>
          </ion-card>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
</ion-content>
