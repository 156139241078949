import {AbstractControl, FormArray, ValidationErrors, ValidatorFn} from "@angular/forms";
import {ScoreEnum} from "../enums/score-enum";
import {isEqual} from "lodash";

export function compareValidator(firstField: string, secondField: string): ValidatorFn {
  return (formGroup: AbstractControl): ValidationErrors | null => {
    const firstControl = formGroup.get(firstField);
    const secondControl = formGroup.get(secondField);
    // Return if control or matching control doesn't exist
    if (!firstControl || !secondControl) {
      return null;
    }
    if ( secondControl.hasError('mustMatch')) {
      delete secondControl.errors!['mustMatch'];
      secondControl.updateValueAndValidity();
    }
    if (secondControl.value == null || secondControl.value.length === 0 || firstControl.value === secondControl.value) {
      return null;
    }
    const errors = {mustMatch: true};
    secondControl.setErrors(errors);
    return errors;
  }
}

export function specializzazioneValidator(professioneField: string, specializzazioneField: string): ValidatorFn {
  return (formGroup: AbstractControl): ValidationErrors | null => {
    const firstControl = formGroup.get(professioneField);
    const secondControl = formGroup.get(specializzazioneField);
    // Return if control or matching control doesn't exist
    if (!firstControl || !secondControl) {
      return null;
    }
    if ( secondControl?.hasError('mustMatch')) {
      delete secondControl.errors!['mustMatch'];
      secondControl.updateValueAndValidity();
    }
    if (!!secondControl.value) {
      return null;
    }
    if(isEqual(firstControl.value,{"id":"36","text":"Altre professioni"}) && !secondControl.value) {
      return null
    }

    const errors = {mustMatch: true};
    secondControl.setErrors(errors);
    return errors;
  }
}

export function arrayNotEmptyValidator(fieldName: string): ValidatorFn {
  return (formGroup: AbstractControl): ValidationErrors | null => {
    const formArray = formGroup.get(fieldName) as FormArray;
    // Return if control or matching control doesn't exist
    if ( formArray.hasError('isEmpty')) {
      delete formArray.errors!['isEmpty'];
      formArray.updateValueAndValidity();
    }
    if((formArray.value as Array<string | null>).some((ele) => !!ele)) {
      return null;
    }
    const errors = {isEmpty: true};
    formArray.setErrors(errors);
    return errors;
  }
}

export function requiredFalseValidator(key?: ScoreEnum): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {

    if (!control) {
      return null;
    }
    if ( control.hasError('isTrue')) {
      delete control.errors!['isTrue'];
      control.updateValueAndValidity({emitEvent: false});
    }
    if (!control.value) {
      return null;
    }
    const errors = !!key ? {isTrue: key} : {isTrue: true};

    control.setErrors(errors, {emitEvent: false});
    return errors;
  }
}

export function requiredNotEqualValidator(targetValue?: any, key?: ScoreEnum): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control) {
      return null;
    }
    if ( control.hasError('isEqual')) {
      delete control.errors!['isEqual'];
      control.updateValueAndValidity({emitEvent: false});
    }
    if (!control.value) {
      return null;
    }

    if (control?.value !== targetValue && control?.value?.id !== targetValue) {
      return null
    }
    const errors = !!key ? {isEqual: key} : {isEqual: true};

    control.setErrors(errors, {emitEvent: false});
    return errors;
  }
}
