import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {catchError, map, retry, switchMap, tap} from 'rxjs/operators';
import {ActivatedRoute, Router} from '@angular/router';

import {LocalStorageService} from "./local-storage.service";
import {Auth, User} from "../shared/interfaces/auth";
import {environment} from "../../environments/environment";
import {UserDTO} from "../../api-clients/generated/services";


@Injectable({providedIn: 'root'})

export class AuthenticationService {
  private currentUserSubject: BehaviorSubject<UserDTO | null | undefined>;
  public currentUser: Observable<UserDTO | null | undefined>;
  private headers = new HttpHeaders(
    {
      'Content-Type': 'application/x-www-form-urlencoded',
      Accept: '*/*',
    }
  );


  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private router: Router,
    private localStorageService: LocalStorageService
  ) {
    this.currentUserSubject = new BehaviorSubject<UserDTO | null | undefined>(this.localStorageService.getProfile());
    this.currentUser = this.currentUserSubject.asObservable();
  }

 /* public get currentUserValue(): UserDTO | null | undefined {
    return this.currentUserSubject.value;
  }

  public get accessToken(): string | null | undefined {
    return this.localStorageService.getAccessToken();
  }*/

  login(username: string, password: string, grantType: GrantType = 'password'): Observable<Auth> {
    const body = new URLSearchParams();
    body.set('username', username);
    body.set('password', password);
    body.set('client_id', environment.KEYCLOAK_CLIENT_ID);
    body.set('grant_type', grantType);
    return this.http.post<Auth>(`${environment.KEYCLOAK_URL}/realms/${environment.KEYCLOAK_REALM}/protocol/openid-connect/token`,
      body, {headers: this.headers});
  }

  refreshToken(refresh_token: string, grantType: GrantType = 'refresh_token'): Observable<Auth> {
    const body = new URLSearchParams();
    body.set('refresh_token', refresh_token);
    body.set('client_id', environment.KEYCLOAK_CLIENT_ID);
    body.set('grant_type', grantType);
    return this.http.post<Auth>(`${environment.KEYCLOAK_URL}/realms/${environment.KEYCLOAK_REALM}/protocol/openid-connect/token`,
      body, {headers: this.headers});
  }

}

export type GrantType = 'password' | 'refresh_token';

export interface ConfigI {
  apiServer?: APIServer;
  configKeycloak?: ConfigKeycloak;
  configApp?: ConfigApp;
}

export interface ConfigApp {
  globalVersion: string;
}

export interface APIServer {
  url?: string;
}

export interface ConfigKeycloak {
  KEYCLOAK_URL?: string;
  KEYCLOAK_REALM?: string;
  KEYCLOAK_CLIENT_ID?: string;
}
