import {APP_INITIALIZER, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {RouteReuseStrategy, RouterModule} from '@angular/router';
import {IonicModule, IonicRouteStrategy} from '@ionic/angular';
import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {AppInitService} from "./services/app-init.service";
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {CommonModule} from "@angular/common";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {ModalModule} from "./shared/modals/modal/modal.module";
import {LoginModule} from "./pages/login/login.module";
import {RegisterPageModule} from "./pages/register/register.module";
import {RecoverPasswordPageModule} from "./pages/recover-password/recover-password.module";
import {LocalStorageService} from "./services/local-storage.service";
import { Configuration } from 'src/api-clients/generated/services/configuration';
import {environment} from "../environments/environment";
import {RefreshTokenInterceptor} from "./interceptors/refresh-token.interceptor";
import {ApiModule} from "../api-clients/generated/services";

@NgModule({

  declarations: [AppComponent],
  imports: [BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    CommonModule,
    BrowserModule,
    IonicModule.forRoot({
      mode: 'ios'
    }),
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    ModalModule,
    LoginModule,
    RegisterPageModule,
    RecoverPasswordPageModule,
    ApiModule,
  ],
  providers: [
    {provide: RouteReuseStrategy, useClass: IonicRouteStrategy},
    {
      provide: Configuration,
      useFactory: (localStorageService: LocalStorageService) => {
        return new Configuration(
          {
            basePath: environment.servicesUrl,
            accessToken: () => {
              return localStorageService.getAccessToken()!
            },
          });
      },
      deps: [LocalStorageService], multi: false
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RefreshTokenInterceptor,
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (appInitService: AppInitService) => () => appInitService.init(), // useFactory è usato perché initializeApp è una funzione e non una classe
      deps: [AppInitService],
      multi: true
    }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}
