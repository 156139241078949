import {Injectable} from '@angular/core';
import {ModalController, ToastController, ToastOptions} from "@ionic/angular";
import {merge} from 'lodash';
import {ModalComponent} from "../shared/modals/modal/modal.component";
import {ModalConfiguration, ModalList} from "../shared/interfaces/modal-configuration-interface";
import {ApplicantModalEnum, ModalRoleEnum} from "../shared/enums/modal-enum";


@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  handlerMessage = '';
  roleMessage = '';
  private _defaultSnackbarConfig: ToastOptions = {
    message: '',
    color: 'success',
    position: 'bottom',
    duration: 5000,
    icon: 'information-circle-outline'
  };

  modals: ModalList = {};
  isSessionInterattivaOpened: boolean | undefined;

  constructor(private toastController: ToastController,
              private modalController: ModalController) {

  }

  async closeAll() {
    const topModal = await this.modalController.getTop();
    if(!!topModal) {
      await this.dismissAll(topModal);
    }
  }

  dismissAll(modal: HTMLIonModalElement) {
    return new Promise ((resolve) => {
      modal.dismiss().then(async res => {
        const getNewTop = await this.modalController.getTop();
        if (getNewTop) {
          resolve(this.dismissAll(getNewTop));
        } else {
          resolve(true);
        }
      });
    });
  }

  openToast(_toastConfiguration: ToastOptions) {
    const config = merge({}, this._defaultSnackbarConfig, _toastConfiguration);
    return this.presentToast(config);
  }

  async presentToast(configuration: ToastOptions) {
    const toast = await this.toastController.create(configuration);
    await toast.present();
    const {role} = await toast.onDidDismiss()
    this.roleMessage = `Dismissed with role: ${role}`;
  }

  async openModal(configuration: ModalConfiguration, applicant?: string): Promise<{ [key: string]: any } | null>{
    const isModalYetOpened = !!applicant && this.getModalByApplicant(applicant);
    if (!isModalYetOpened) {
      const modal = await this.modalController.create({
        component: ModalComponent,
        componentProps: configuration,
        cssClass: configuration.cssClass,
        backdropDismiss: !!configuration.backdropDismiss
      });
      if (!!applicant) {
        modal.present().then(() =>
          this.setModalByApplicant(applicant, modal)
        );
      } else {
        modal.present();
      }

      const {data, role} = await modal.onWillDismiss().then(
        (value) => {
          if (!!applicant) {
            this.setModalByApplicant(applicant, undefined)
          }
          return value;
        }
      );
      if (role === ModalRoleEnum.CONFIRM) {
        return data
      }
      return null;
    }
    return null
  }


  //----- MARK: SET e GET delle modali -----

  setPushModal(modal: HTMLIonModalElement | undefined) {
    this.modals.pushModal = modal;
  }

  getPushModal(): HTMLIonModalElement | undefined {
    return this.modals.pushModal;
  }

  getModalByApplicant(applicant?: ApplicantModalEnum | string): HTMLIonModalElement | undefined {
    switch (applicant) {
      default:
        return (!!applicant && !!this.modals[applicant]) ? this.modals[applicant] : undefined;
    }
  }

  setModalByApplicant(applicant: ApplicantModalEnum | string, modal: HTMLIonModalElement | undefined) {
    switch (applicant) {
      default:
        this.modals[applicant] = modal;
        break;
    }
  }

//----- ENDMARK: SET e GET delle modali -----

//----- MARK: DISMISS delle modali -----
  dismissAllModals() {
    Object.values(this.modals).forEach((modal) => {
      if (!!modal) {
        modal.dismiss();
      }
    });
  }

//----- ENDMARK: DISMISS delle modali -----

}
