import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule} from "@angular/forms";
import {IonicModule} from "@ionic/angular";
import {ModalComponent} from "./modal.component";
import {TermsAndConditionsModule} from "../../../components/terms-and-conditions/terms-and-conditions.module";


@NgModule({
  declarations: [ModalComponent],
    imports: [
        CommonModule,
        IonicModule,
        FormsModule,
        ReactiveFormsModule,
        TermsAndConditionsModule,
    ],
  providers: [
  ]
})
export class ModalModule { }
