import {NgModule} from '@angular/core';
import {RouterModule, Routes} from "@angular/router";

import {LoginComponent} from "./login.component";
import {PathEnum} from "../../shared/enums/path-enum";

const routes: Routes = [
  {
    path: '',
    component: LoginComponent,
    children: [],
  },
  {path: '**', pathMatch: 'full', redirectTo: PathEnum.LOGIN}
];


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LoginRoutingModule {
}
