export const emailRegex = '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$';
export const passwordRegex = "^(?=.{8,}$)(?=.*[a-zA-Z])(?=.*\\d)(?=.*[!@#$%.,^&*]).*$"; //La password deve contenere 8 caratteri alfanumerici, almeno un carattere numerico e almeno uno di questi caratteri speciali: !@#$%.,^&*
export const urlRegex = '^(http:\\/\\/www\\.|https:\\/\\/www\\.|http:\\/\\/|https:\\/\\/)?[a-z0-9]+([\\-\\.]{1}[a-z0-9]+)*\\.[a-z]{2,5}(:[0-9]{1,5})?(\\/.*)?$';
export const decimalRegex = '^[0-9]+(.[0-9]{0,2})?$';
export const cellularRegex = '^\\+?\\d*$';
export const noSpaceRegex = '^\\S+$';
export const fiscalCodeRegex = '^(?:[A-Z][AEIOU][AEIOUX]|[AEIOU]X{2}|[B-DF-HJ-NP-TV-Z]{2}[A-Z]){2}(?:[\\dLMNP-V]{2}(?:[A-EHLMPR-T](?:[04LQ][1-9MNP-V]|[15MR][\\dLMNP-V]|[26NS][0-8LMNP-U])|[DHPS][37PT][0L]|[ACELMRT][37PT][01LM]|[AC-EHLMPR-T][26NS][9V])|(?:[02468LNQSU][048LQU]|[13579MPRTV][26NS])B[26NS][9V])(?:[A-MZ][1-9MNP-V][\\dLMNP-V]{2}|[A-M][0L](?:[1-9MNP-V][\\dLMNP-V]|[0L][1-9MNP-V]))[A-Z]$';

export const atLeastOneNumber = '.*[0-9].*';
export const atLeastOneLetter = '.*[a-zA-z].*';
export const atLeastOneSpecialCharacter = '.*?[!@#$%.,^&*]'
export const atLeastEightCharacters = '.{8,}'
