import { Injectable } from '@angular/core';
import {ModalController, ToastController} from "@ionic/angular";
import {BehaviorSubject, Subject} from "rxjs";


@Injectable({
  providedIn: 'root'
})
export class GlobalService {

  loadedUrl$ = new BehaviorSubject<string>(this.getLoadedUrl());
  loadedUrl: string = '';
  scannerValue$ = new Subject<string>();
  isMobilePlatform = false;
  appVersion = '';

  constructor(private toastController: ToastController,
              private modalController: ModalController) {

  }

  setLoadedUrl(url: string) {
    this.loadedUrl = url;
    this.loadedUrl$.next(this.getLoadedUrl());
  }

  getLoadedUrl(): string {
    return this.loadedUrl;
  }

  setScannerValue(value: string) {
    this.scannerValue$.next(value);
  }

}
