/**
 * VTE Risk Calculator Backend API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface UserDTO { 
    id?: string;
    created?: Date;
    username?: string;
    firstName?: string;
    lastName?: string;
    email?: string;
    password?: string;
    attributes?: { [key: string]: Array<string>; };
    conditionsTerminiServizio?: boolean;
    conditionsDatiFunzionamento?: boolean;
    conditionsDatiElaborazioneStatistica?: boolean;
    conditionsDatiFinalitaComunicazione?: boolean;
    conditionsDatiFinalitaMessaggi?: boolean;
    conditionsDatiFinalitaMiglioramento?: boolean;
    roles?: Array<string>;
}