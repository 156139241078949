import {Component, OnInit} from '@angular/core';
import {GlobalService} from "./services/global.service";
import {Location} from "@angular/common";
import {ActivatedRoute, Router} from "@angular/router";
import {Observable, of, switchMap, tap} from "rxjs";
import {get} from "lodash";
import {UserControllerService} from "../api-clients/generated/services";
import {LocalStorageService} from "./services/local-storage.service";
import {Auth} from "./shared/interfaces/auth";
import {ProfileManagerService} from "./services/profile-manager.service";
import {PathEnum} from "./shared/enums/path-enum";
import {NavController} from "@ionic/angular";
import {PushManagerService} from "./services/push-manager.service";


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {

  constructor(private location: Location,
              private globalService: GlobalService,
              private route: ActivatedRoute,
              private userControllerService: UserControllerService,
              private localStorageService: LocalStorageService,
              private profileManagerServiceService: ProfileManagerService,
              private pushManagerService: PushManagerService) {
  }

  ngOnInit() {
    this.location.onUrlChange((url, state) => {
      this.globalService.setLoadedUrl(url);
      if (!!url && url?.includes('pages') && !url?.includes('profile')) {
        this.profileManagerServiceService.checkProfileState();
      }
      if (!!url
        && !(url?.includes('auto') || url.includes('dettaglio-auto-score'))
      ) {
        this.localStorageService.cleanAutoAge$().subscribe()
      }
    });

    this.pushManagerService.inizializzaPush();
  }

}
