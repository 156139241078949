import { Injectable, NgZone } from '@angular/core';
import {Network, NetworkStatus} from "@capacitor/network";
import {ConnectionStatus} from "@capacitor/network/dist/esm/definitions";
import {BehaviorSubject, from, fromEventPattern, Observable, tap} from "rxjs";
import {PluginListenerHandle} from "@capacitor/core";

@Injectable({
  providedIn: 'root'
})
export class NetworkService {
  networkStatus: ConnectionStatus | undefined;
  private isOnline$: Observable<boolean> | undefined;
  private handler: PluginListenerHandle;
  private status = new BehaviorSubject<ConnectionStatus | null>(null);

  constructor() {
    this.handler = Network.addListener("networkStatusChange", (status) => {
      console.log("31 Network status changed", status);
      this.sendStatusChangeMsg(status);
    });
  }

  getStatusChangeObservable$(): Observable<ConnectionStatus | null> {
    return this.status.asObservable().pipe(
      tap((connectionStatus) => console.log('AAA connection status in network change', connectionStatus))
    );
  }

  sendStatusChangeMsg(status: ConnectionStatus): void {
    this.status.next(status);
  }

  async getNetWorkStatus(): Promise<boolean> {
    this.networkStatus = await Network.getStatus();
    console.log(this.networkStatus);
    return !this.networkStatus?.connected;
  }

  // true = sono OFFLINE
  getNetworkStatus$(): Observable<boolean> {
    return from(this.getNetWorkStatus())
  }
}
