import {HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {NotificationService} from "./notification.service";

export enum RequestTypesEnum {
  LOGIN = 'LOGIN',
  REGISTRAZIONE = 'REGISTRAZIONE',
  ERRORE_GENERICO = 'ERRORE_GENERICO',
  DELETE_PROFILE = 'DELETE_PROFILE',
  UPDATE_PROFILE = 'UPDATE_PROFILE',
  UPDATE_PASSWORD = 'UPDATE_PASSWORD',
  GET_PROFILE = 'GET_PROFILE',
  ERRORE_RECUPERA_PASSWORD = 'ERRORE_RECUPERA_PASSWORD',

  TIMEOUT = 'TimeoutError',
  OFFLINE = 'OFFLINE',
  CONFIGURAZIONE_APP = 'CONFIGURAZIONE_APP'
}


@Injectable({
  providedIn: 'root'
})
export class ManageErrorService {

  constructor(private notificationService: NotificationService) {
  }

  manageError(error: HttpErrorResponse, requestType: RequestTypesEnum) {
    let httpStatus: number = error.status;
    let errorCode: number = !!error.error ? error.error.code : null;
    let errorMessage: string = !!error.error ? error.error.message : null;
    let errorType: string = !!error.error ? error.error.error : null
    let messageToShow: string;
    messageToShow = this.getErrorMessage(requestType, httpStatus, errorCode, errorMessage, errorType);
    this.notificationService.openToast({message: messageToShow, color: 'danger'})
  }

  getErrorMessage(requestType: RequestTypesEnum, httpStatus: number, errorCode: number, errorMessage: string, errorType?: string): string {
    let defaultMessage = 'Si è verificato un errore, si prega di riprovare.';
    let defaultLogInMessage = 'Si è verificato un errore durante richiesta di log in, si prega di riprovare.';
    let message;

    switch (requestType) {

      case (RequestTypesEnum.LOGIN):
        if (!!httpStatus) {
          switch (httpStatus) {

            case (401):
              return message = !!errorType && (errorType === 'invalid_grant') ? 'Le credenziali inserite non sono corrette, si prega di riprovare.' : defaultLogInMessage;

            default:
              return defaultLogInMessage;
          }
        } else
          return defaultLogInMessage;

      case (RequestTypesEnum.REGISTRAZIONE):
        if (!!httpStatus) {
          switch (httpStatus) {
            case (500):
              switch (errorCode) {
                case(410):
                  return message = 'L\'email inserita risulta già in uso, si prega di usare una mail diversa o di provare a recuperare la password.';
                default:
                  return defaultMessage
              }
            /*case (400):
              return message = 'L\'email inserita risulta già in uso, si prega di usare una mail diversa o di provare a recuperare la password.';
            */
            default:
              return defaultMessage;
          }
        } else
          return defaultMessage;
      case (RequestTypesEnum.UPDATE_PROFILE):
        return 'Errore durante la richiesta di modifica del profilo. Si prega di riprovare';
      case (RequestTypesEnum.DELETE_PROFILE):
        return 'Errore durante richiesta di cancellazione del profilo. Si prega di riprovare';
      case (RequestTypesEnum.GET_PROFILE):
        return 'Errore durante il caricamento dei dati del profilo. Si prega di riprovare';
      case (RequestTypesEnum.UPDATE_PASSWORD):
        return 'Errore durante l\'aggiornamento della password. Si prega di riprovare';
      case (RequestTypesEnum.ERRORE_RECUPERA_PASSWORD):
        switch (errorCode) {
          case(411):
            return 'Recupero password non riuscito. Email non presente';
          default:
            return 'Errore nell\'invio della richiesta di recupero password';
        }
      case (RequestTypesEnum.TIMEOUT):
        return 'Attenzione, connessione scarsa o assente. I dati visualizzati potrebbero non essere aggiornati.';
      case (RequestTypesEnum.ERRORE_GENERICO):
        return 'Errore durante il caricamento dei dati';
      default:
        return defaultMessage;
    }
  }


}
