import {Injectable} from '@angular/core';
import {LocalStorageService} from "./local-storage.service";
import {UserControllerService, UserDTO} from "../../api-clients/generated/services";
import {ManageErrorService, RequestTypesEnum} from "./manage-error.service";
import {ModalController, NavController} from "@ionic/angular";
import {LoaderService} from "./loader.service";
import {NotificationService} from "./notification.service";
import {Router} from "@angular/router";
import {Location} from "@angular/common";
import {GlobalService} from "./global.service";
import {FormGroup, Validators} from "@angular/forms";
import {catchError, finalize, Observable, pipe, switchMap, tap, throwError, timeout, UnaryFunction} from "rxjs";
import {ModalRoleEnum} from "../shared/enums/modal-enum";
import {HttpErrorResponse} from "@angular/common/http";
import {ModalConfiguration} from "../shared/interfaces/modal-configuration-interface";
import {PathEnum} from "../shared/enums/path-enum";
import {UserAttributes} from "../shared/interfaces/user-attributes";
import {head, isEmpty} from "lodash";
import {NetworkService} from "./network.service";

@Injectable({
  providedIn: 'root'
})
export class ProfileManagerService {

  isModalForConditionsTerminiServizioOpened = false;
  isModalForProfessioneOpened = false;

  constructor(
    private localStorageService: LocalStorageService,
    private userControllerService: UserControllerService,
    private manageErrorService: ManageErrorService,
    public navCtrl: NavController,
    private loaderService: LoaderService,
    private notificationService: NotificationService,
    private modalController: ModalController,
    private networkService: NetworkService,
  ) {
  }

  checkProfileState() {
    if (!this.localStorageService.getProfile() || isEmpty(this.localStorageService.getProfile())) {
      this.getProfile();
    } else {
      this.checkProfileAttributeStates();
    }
  }

  checkProfileAttributeStates() {
    const profile = this.localStorageService.getProfile();
    console.log('AAA profilo', profile);
    if ((
        !profile?.conditionsTerminiServizio
        || !profile?.conditionsDatiFunzionamento
        || !profile?.conditionsDatiElaborazioneStatistica
        || !profile?.conditionsDatiFinalitaComunicazione)
      && !this.isModalForConditionsTerminiServizioOpened) {
      this.isModalForConditionsTerminiServizioOpened = true;
      this.openModalForConditionsTerminiServizio();
    } else if (this.checkSpecializzazionePresence() && !this.checkProfessionePresence() && !this.isModalForProfessioneOpened) {
      this.isModalForProfessioneOpened = true;
      this.openModalForProfessione();
    }
  }

  //Eliminare ciclicità?
  getProfile(form?: FormGroup) {
    this.userControllerService.getUser().pipe(
      timeout(5000),
      switchMap((user) => this.localStorageService.setPreferenceProfile$(user)),
      catchError((err) => {
          console.error(err);
          if (err.name === RequestTypesEnum.TIMEOUT || err.status === 408) {
            return this.localStorageService.getProfile$();
          } else {
            return throwError(() => err);
          }
        }
      ),
      finalize(() => this.loaderService.hideLoader())
    ).subscribe({
      next: () => {
        if (!!form) {
          this.modalController.dismiss(true, ModalRoleEnum.CONFIRM);
        }
        this.checkProfileAttributeStates();
      },
      error: (error: HttpErrorResponse) => {
        console.error(error)
        if (!form) {
          this.openRetryGetUserModal()
        } else {
          this.manageErrorService.manageError(error, RequestTypesEnum.GET_PROFILE);
        }
      }
    })
  }

  openRetryGetUserModal() {
    const retryModalConfiguration: ModalConfiguration = {
      backdropDismiss: false,
      form: {
        'confirm': [true, Validators.required]
      },
      text: {
        modal_title: 'ATTENZIONE',
        modal_message: 'Il caricamento del profilo non è andato a buon fine. Si prega di riprovare per poter proseguire.',
        confirm_button: 'Riprova',
      },
      conditions: {
        hidden_form: true,
        confirm_button: true,
        cancel_button: false
      },
      functions: {
        confirm_function: (form) => this.getProfile(form)
      },
      cssClass: 'generic-modal'
    }
    this.notificationService.openModal(retryModalConfiguration);
  }

  openModalForConditionsTerminiServizio() {
    const redirectModalConfiguration: ModalConfiguration = {
      backdropDismiss: false,
      form: {
        'confirm': [true, Validators.required]
      },
      text: {
        modal_title: 'ATTENZIONE',
        modal_message: 'La Sua privacy è importante. Per accedere a VTE Risk Calculator Le chiediamo di leggere il documento "Informazioni legali, condizioni e termini del Servizio" e accettare le privacy',
        confirm_button: 'Ok',
      },
      conditions: {
        hidden_form: true,
        confirm_button: true,
        cancel_button: false
      },
      functions: {
        confirm_function: (form) => {
          this.navCtrl.navigateRoot(PathEnum.PROFILE);
          this.modalController.dismiss(true, ModalRoleEnum.CONFIRM);
          this.isModalForConditionsTerminiServizioOpened = false;
        }
      },
      cssClass: 'generic-modal'
    }
    this.notificationService.openModal(redirectModalConfiguration, 'ModalForConditionsTerminiServizio');
  }

  openModalForProfessione() {
    const redirectModalConfiguration: ModalConfiguration = {
      backdropDismiss: false,
      form: {
        'confirm': [true, Validators.required]
      },
      text: {
        modal_title: 'ATTENZIONE',
        modal_message: 'Attenzione! È necessario inserire la professione e aggiornare la specializzazione.',
        confirm_button: 'Ok',
      },
      conditions: {
        hidden_form: true,
        confirm_button: true,
        cancel_button: false
      },
      functions: {
        confirm_function: (form) => {
          this.navCtrl.navigateRoot(PathEnum.PROFILE);
          this.modalController.dismiss(true, ModalRoleEnum.CONFIRM);
          this.isModalForProfessioneOpened = false;
        }
      },
      cssClass: 'generic-modal'
    }
    this.notificationService.openModal(redirectModalConfiguration, 'ModalForProfessione');
  }

  checkProfessionePresence() {
    const attributes = this.localStorageService.getProfile()?.attributes as UserAttributes;
    return !!head(attributes.professione)
  }

  checkSpecializzazionePresence() {
    const attributes = this.localStorageService.getProfile()?.attributes as UserAttributes;
    return !!head(attributes.specializzazione)
  }


}
