import {Injectable} from '@angular/core';
import {
  BehaviorSubject,
  catchError,
  forkJoin,
  from,
  map,
  Observable,
  of,
  switchMap,
  tap,
  throwError,
  timeout
} from "rxjs";
import {TokenResponse} from "./saml-auth.service";
import jwt_decode from 'jwt-decode';
import {Auth} from "../shared/interfaces/auth";
import {Configurazione, UserDTO} from "../../api-clients/generated/services";
import {Preferences} from '@capacitor/preferences';
import {RequestTypesEnum} from "./manage-error.service";


@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  checkLoggedUser$ = new BehaviorSubject(!!this.getAccessToken());
  checkUpdateProfile$ = new BehaviorSubject(!!this.getProfile());
  currentToken: any | null = null;
  private autologinResponse: any | undefined;
  private device_id: string | undefined;
  tokenResponse: TokenResponse | undefined;

  profile: UserDTO | undefined;
  refreshToken: string | null | undefined;
  accessToken: string | undefined | null;

  constructor() {
  }

  //// ------ GET
  getAccessToken(): string | undefined | null {
    return this.accessToken;
  }

  getRefreshToken(): string | undefined | null {
    return this.refreshToken
  }

  getProfile(): UserDTO | undefined {
    return this.profile;
  }


  get isLogged() {
    return !!this.getAccessToken()
  }

  //----MARK: GENERIC ----//

  // generic get
  async checkPreferenceToken(key: StorageProperties) {
    const check = await Preferences.get({
      key: key,
    });
    return check.value;
  }

  // generic set
  async setPreferenceToken(key: StorageProperties, value: string) {
    await Preferences.set({
      key: key,
      value: value,
    });
  }

  // generic clean/remove
  async removePreferenceToken(key: StorageProperties) {
    await Preferences.remove({
      key: key,
    });
  }

  //----ENDMARK: GENERIC ----//

  //----MARK: FUNZIONI DI GET----//
  getAccessToken$(): Observable<string | null> {
    return from(this.checkPreferenceToken(StorageProperties.ACCESS_TOKEN)).pipe(
      tap((value) => {
        this.accessToken = value;
        this.checkLoggedUser$.next(true);
      })
    );
  }

  getProfile$(): Observable<UserDTO> {
    return from(this.checkPreferenceToken(StorageProperties.PROFILE)).pipe(
      map((profile) => !!profile ? JSON.parse(profile) : null),
      tap((profile) => {
        this.profile = profile;
      })
    );
  }

  getConfiguration$(): Observable<Configurazione> {
    return from(this.checkPreferenceToken(StorageProperties.CONFIGURATION)).pipe(
      map((configurazione) => !!configurazione ? JSON.parse(configurazione) : null),
    );
  }

  getRefreshToken$(): Observable<string | null> {
    return from(this.checkPreferenceToken(StorageProperties.REFRESH_TOKEN)).pipe(
      tap((value) => {
        console.log('AAA valore', value)
        this.refreshToken = value;
      })
    );
  }

  getAutoAge$(): Observable<number | null> {
    return from(this.checkPreferenceToken(StorageProperties.AGE_FIELD_IN_PAGE_AUTO)).pipe(
      map((value) => Number(value))
    )
  }

  //----ENDMARK: FUNZIONI DI GET----//

  //----MARK: FUNZIONI DI SET ----//

  setPreferenceProfile$(user: UserDTO): Observable<UserDTO> {
    if(!!user) {
      return from(Preferences.set({
        key: StorageProperties.PROFILE,
        value: JSON.stringify(user),
      })).pipe(
        tap(() => {
          this.profile = user;
          this.checkUpdateProfile$.next(true);
        }),
        map(() => user),
      );
    } else {
      return throwError(() => new Error)
    }

  }

  setPreferenceConfigurazione$(configuration: Configurazione): Observable<Configurazione> {
    return from(Preferences.set({
      key: StorageProperties.CONFIGURATION,
      value: JSON.stringify(configuration),
    })).pipe(
      map(() => configuration),
    );
  }

  setAccessToken$(value: string) {
    return from(this.setPreferenceToken(StorageProperties.ACCESS_TOKEN, value)).pipe(
      tap(() => {
        this.accessToken = value;
        this.checkLoggedUser$.next(true);
      })
    );
  }

  setRefreshToken$(value: string) {
    return from(this.setPreferenceToken(StorageProperties.REFRESH_TOKEN, value)).pipe(
      tap(() => this.refreshToken = value)
    );
  }

  setExpires$(value: number) {
    return from(this.setPreferenceToken(StorageProperties.EXPIRATION, String(value)));
  }

  setAutoAge$(value: number) {
    return from(this.setPreferenceToken(StorageProperties.AGE_FIELD_IN_PAGE_AUTO, String(value)));
  }

  //----ENDMARK: FUNZIONI DI SET ----//

  decodeToken(token: string): TokenI | null {
    if (token) {
      return jwt_decode(token);
    }
    return null;
  }

  isAdmin$(): Observable<boolean> {
    return this.getAccessToken$().pipe(
      map((accessToken) =>
        !!accessToken && !!this.decodeToken(accessToken)?.realm_access.roles.includes(UserRolesEnum.ADMIN))
    )
  }

  setAuth$(auth: Auth) {
    return this.setToken$(auth.access_token!, auth.refresh_token!);
  }


  setToken$(access_token: string, refresh_token: string) {
    return forkJoin(
      [
        this.setAccessToken$(access_token),
        this.setRefreshToken$(refresh_token)
      ]
    ).pipe(
      tap(() => {
        this.checkLoggedUser$.next(true);
      })
    )
  }
  //----ENDMARK: FUNZIONI DI SET ----//

  //----MARk: FUNZIONI DI CLEAN ----//
  cleanAutoAge$() {
    return from(this.removePreferenceToken(StorageProperties.AGE_FIELD_IN_PAGE_AUTO))
  }

  cleanPreferenceToken$() {
    return forkJoin(
      [
        from(this.removePreferenceToken(StorageProperties.ACCESS_TOKEN)),
        from(this.removePreferenceToken(StorageProperties.REFRESH_TOKEN)),
        from(this.removePreferenceToken(StorageProperties.EXPIRATION)),
        from(this.removePreferenceToken(StorageProperties.PROFILE)),
      ]
    ).pipe(
      tap(() => {
        this.profile = undefined;
        this.accessToken = undefined;
        this.refreshToken = undefined;
        this.checkLoggedUser$.next(false);
        this.checkUpdateProfile$.next(false);
      })
    )
  }

  //----ENDMARk: FUNZIONI DI CLEAN ----//
  async getMyAccessToken(): Promise<string | null> {
    let strObject = await Preferences.get({key: StorageProperties.ACCESS_TOKEN});
    if (!!strObject) {
      return strObject as unknown as string
    }
    return null
  }
}

export enum StorageProperties {
  TOKEN_RESPONSE = 'TOKEN_RESPONSE',
  ACCESS_TOKEN = 'access_token',
  REFRESH_TOKEN = 'refresh_token',
  EXPIRATION = 'expiration',
  PROFILE = 'user',
  CONFIGURATION = 'configuration',
  AGE_FIELD_IN_PAGE_AUTO = 'age',
}

export enum UserRolesEnum {
  ADMIN = 'admin',
}

export interface TokenI {
  realm_access: RealmAccessI;
}

export interface RealmAccessI {
  roles: string[];
}

