<div class="inner-content">
  <ion-toolbar color="primary">
    <ion-title class="ion-text-center ion-no-padding ion-text-uppercase fs-pt-16">{{modalTitle}}</ion-title>
  </ion-toolbar>

  <ng-container *ngIf="!!modal_subtitle">
    <div class="ion-padding" style="text-align: center">
      <ion-label class="fs-pt-16"><b>{{modalMessage}}</b></ion-label>
    </div>
  </ng-container>

  <br>

  <ng-container *ngIf="modalMessage && modalMessage !== ''">
    <div class="ion-padding" style="text-align: center">
      <ion-label class="fs-pt-14">{{modalMessage}}</ion-label>
    </div>
  </ng-container>

  <ng-container *ngFor="let field of getFields()">
    <ion-item *ngIf="!!field">
      <ion-label position="floating">{{field | titlecase}}</ion-label>
      <ion-input [formControl]="getFormCtrl(field)"></ion-input>
      <ion-note color="danger" class="ion-text-justify"
                *ngIf="(field === 'password') && getFormCtrl(field).touched && getFormCtrl(field).hasError('pattern')"
                slot="error">
        {{errorMessagePasswordPattern}}
      </ion-note>
      <ion-note color="danger" class="ion-text-justify"
                *ngIf="(field === 'e-mail') && getFormCtrl(field).touched && getFormCtrl(field).hasError('pattern')"
                slot="error">
        {{errorMessageEmailPattern}}
      </ion-note>
    </ion-item>

  </ng-container>

  <ng-container *ngIf="areTermsAndConditionsVisible">
    <app-terms-and-conditions></app-terms-and-conditions>
  </ng-container>

  <ion-grid>
    <ion-row class="ion-justify-content-center ion-align-items-center">
      <ion-col size-sm="12" size-xl="6" size-md="8">
        <ion-button *ngIf="isConfirmButtonVisible" class="ion-margin-top ion-text-uppercase"
                    [disabled]="formGroup.invalid"
                    color="primary" (click)="confirmFunction(formGroup)" fill="solid"
                    expand="block">{{confirmButtonText | uppercase}}</ion-button>
        <ion-button *ngIf="isCancelButtonVisible" class="ion-margin-top ion-text-uppercase"
                    color="danger" (click)="cancel()" fill="solid"
                    expand="block">{{cancelButtonText | uppercase}}</ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</div>
