import {Injectable} from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import {
  BehaviorSubject,
  catchError,
  distinctUntilChanged,
  Observable,
  switchMap,
  take,
  throwError,
  timer,
  of, from, map, tap
} from 'rxjs';





import {Router} from '@angular/router';
import {filter, mergeMap} from 'rxjs/operators';
import {LocalStorageService} from "../services/local-storage.service";
import {SamlAuthService} from "../services/saml-auth.service";
import {AuthenticationService} from "../services/authentication.service";
import {NotificationService} from "../services/notification.service";


@Injectable()
export class RefreshTokenInterceptor implements HttpInterceptor {

  isTokenRefreshing: BehaviorSubject<boolean | undefined> | undefined;
  private errorRefresh: string = 'Errore durante l\'aggiornamento della sessione. Si prega di rieseguire la login.';
  offlineErrorMessage = 'Attenzione, connessione scarsa o assente. I dati visualizzati potrebbero non essere aggiornati.'


  /**
   * Constructor
   */
  constructor(
    private localStorageService: LocalStorageService,
    private router: Router,
    private authenticationService: AuthenticationService,
    private notificationService: NotificationService,
  ) {
  }

  /**
   * Intercept
   *
   * @param httpRequest
   * @param next
   */
  intercept(httpRequest: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const authReq = httpRequest.clone();

    if (authReq.url.includes('login') || authReq.url.includes('token')) {
      console.log('Intercettata chiamata di login o refresh token, non utilizzo interceptor');
      return next.handle(authReq);
    }

    if (httpRequest.method !== 'OPTIONS') {
      const token = authReq.headers.get('Authorization');
      const parsedToken = token?.slice(7);
      console.log('Normal token');
      return this.handleRequest(authReq, next);
    } else {
      return next.handle(authReq);
    }
  }

  handleRequest(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // @ts-ignore
    return next.handle(request).pipe(
      catchError(
        (error: HttpErrorResponse) => {
          if (error.status === 401) {
            if (!!this.isTokenRefreshing) {
              console.log('isTokenRefreshing eiste');
              return this.isTokenRefreshing.pipe(
                filter(value => value !== undefined),
                take(1),
                switchMap(
                  (result) => {
                    if (result) {
                      console.log('Chiamata handlerequest 1');
                      return timer(500).pipe(
                        switchMap(() => this.handleRequest(request.clone({
                          setHeaders: {
                            Authorization: 'Bearer ' + this.localStorageService.getAccessToken(),
                          },
                        }), next)));
                    } else {
                      return of(null);
                    }
                  }
                )
              );
            } else {
              console.log('isTokenRefreshing non esiste');
              this.isTokenRefreshing = new BehaviorSubject<boolean | undefined>(undefined);
              return this.localStorageService.getRefreshToken$().pipe(
                switchMap((refreshToken) => this.authenticationService.refreshToken(refreshToken!)),
                distinctUntilChanged(),
                take(1),
                switchMap((result) => this.localStorageService.setAuth$(result).pipe(map(() => result))),
                mergeMap((result) => {
                  this.isTokenRefreshing?.next(true);
                  this.isTokenRefreshing = undefined;
                  console.log('Chiamata handlerequest 2');
                  return this.handleRequest(request.clone({
                    setHeaders: {
                      Authorization: 'Bearer ' + result.access_token,
                    },
                  }), next);
                }),
                catchError((tokenError) => {
                  if (tokenError.url.includes('token')) {
                    this.isTokenRefreshing?.next(false);
                    this.isTokenRefreshing = undefined;
                    return this.localStorageService.cleanPreferenceToken$().pipe(
                      switchMap(() => from(this.notificationService.closeAll())),
                      tap(() => this.router.navigateByUrl('/sign-in')),
                      tap(() => this.notificationService.openToast({
                        message: 'Errore nel refresh del token',
                        color: 'danger'
                      })),
                      switchMap(() => of(null))
                    )
                  } else {
                    return throwError(() => error);
                  }
                }));
            }
          } else if (error?.status === 403 && error?.error?.code === 1009) {
            this.router.navigateByUrl('/sign-in');
            return of(null);
          } else if (error?.status === 408) {
            this.notificationService.openToast({message: this.offlineErrorMessage, color: 'danger'})
            return throwError(() => error);
          } else {
            return throwError(() => error);
          }
        }));
  }
}
