import {Component, Input, OnInit} from '@angular/core';
import {ModalController} from "@ionic/angular";
import {FormBuilder, FormControl, FormGroup} from "@angular/forms";
import {get, upperCase, upperFirst} from "lodash";
import {
  ModalConditionsConfiguration,
  ModalFormConfiguration, ModalFunctionConfiguration,
  ModalTextConfiguration
} from "../../interfaces/modal-configuration-interface";
import {NotificationService} from "../../../services/notification.service";
import {LocalStorageService} from "../../../services/local-storage.service";
import {ModalRoleEnum} from "../../enums/modal-enum";

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent implements OnInit {

  @Input('form') set form(form: ModalFormConfiguration) {
    this.modalForm = form;
    this.formGroup = this.fb.group(this.modalForm);
  }

  @Input('text') set text(text: ModalTextConfiguration) {
    this.modalTitle = get(text, 'modal_title', '') as string;
    this.modal_subtitle = get(text, 'modal_subtitle', null) as string;
    this.modalMessage = get(text, 'modal_message', '') as string;
    this.confirmButtonText = get(text, 'confirm_button', 'Confirm') as string;
    this.cancelButtonText = get(text, 'cancel_button', 'Cancel') as string;
  }

  @Input('conditions') set conditions(conditions: ModalConditionsConfiguration) {
    this.isConfirmButtonVisible = !!get(conditions, 'confirm_button', true);
    this.isCancelButtonVisible = !!get(conditions, 'cancel_button', true);
    this.areTermsAndConditionsVisible = !!get(conditions, 'terms_conditions', false);
    this.isHiddenForm = !!get( conditions, 'hidden_form', false);
  }

  @Input('functions') set functions(functions: ModalFunctionConfiguration) {
    if(!!functions.confirm_function) {
      this.confirmFunction = functions.confirm_function
    } else {
      this.confirmFunction = this.confirm
    }
  }

  modalForm: any;
  formGroup: FormGroup = new FormGroup<any>({});
  modalTitle: string = '';
  modal_subtitle: string | undefined | null;
  modalMessage: string = '';
  confirmButtonText: string = 'Confirm';
  cancelButtonText: string = 'Cancel';
  isConfirmButtonVisible = true;
  isCancelButtonVisible = true;
  areTermsAndConditionsVisible = false;
  isHiddenForm = false;
  confirmFunction: (form: any) => void;
  errorMessagePasswordPattern = 'La password deve contenere almeno 8 caratteri. Di cui almeno un numero, una lettera e uno di questi caratteri speciali: !@#$%.,^&*';
  errorMessageEmailPattern = 'E-mail non valida'

  constructor(private modalController: ModalController,
              private fb: FormBuilder) {
    this.confirmFunction = this.confirm;
  }

  ngOnInit() {
  }

  cancel() {
    return this.modalController.dismiss(null, ModalRoleEnum.CANCEL);
  }

  confirm(form: FormGroup) {
    return this.modalController.dismiss(form.value, ModalRoleEnum.CONFIRM);
  }

  getFormCtrl(field: string) {
    return this.formGroup?.get(field) as FormControl;
  }

  getFields() {
    return (!!this.modalForm && !this.isHiddenForm) ? Object.keys(this.modalForm) : null;
  }

  upperFirst(text: string) {
    return upperFirst(text);
  }

  upperCase(text: string) {
    return upperCase(text);
  }

}

