<ion-header>
  <ion-toolbar color="white" class="no-border-toolbar">
    <ion-title>
      <ion-img src="assets/images/c_LOGO_orizzontale_1.png" [style]="'height:40px'"></ion-img>
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div class="ion-no-padding full-h-percent">
    <ion-grid class="ion-no-padding full-h-percent">
      <ion-row class="ion-justify-content-center ion-align-items-center full-h-percent">
        <ion-col size-sm="12" size-xl="6" size-md="8" class="ion-no-padding">
          <ion-card>
            <ion-grid class="full-w-percent">
              <ion-row>
                <ion-col>
                  <!--ITEMS-->
                  <ion-item>
                    <ion-label position="floating">Password</ion-label>
                    <ion-input [formControl]="ctrlPassword" [placeholder]="'Inserisci password'"
                               type="password" #passwordField></ion-input>
                    <ion-icon name="key-outline" slot="start" color="tertiary"></ion-icon>
                    <ion-buttons slot="end" style="display: block; margin: auto">
                      <ion-button
                        (click)="passwordField.type === 'password' ? passwordField.type = 'text' : passwordField.type = 'password'">
                        <ion-icon *ngIf="passwordField.type === 'password'" slot="icon-only" name="eye-off-outline"
                                  color="tertiary"></ion-icon>
                        <ion-icon *ngIf="passwordField.type === 'text'" slot="icon-only" name="eye-outline"
                                  color="tertiary"></ion-icon>
                      </ion-button>
                    </ion-buttons>
                    <ion-note *ngIf="ctrlPassword.touched && !ctrlPassword.hasError('pattern')" slot="error">Password obbligatoria</ion-note>
                    <ion-note *ngIf="ctrlPassword.touched && ctrlPassword.hasError('pattern')" slot="error">
                      La password deve contenere almeno 8 caratteri. Di cui almeno un numero, una lettera e uno di questi caratteri speciali: !@#$%.,^&*</ion-note>
                  </ion-item>

                  <ion-item>
                    <ion-label position="floating">Conferma password</ion-label>
                    <ion-input [formControl]="ctrlConfirmPassword" [placeholder]="'Inserisci di nuovo la password'"
                               type="password" #confirmPasswordField></ion-input>
                    <ion-icon name="key-outline" slot="start" color="tertiary"></ion-icon>
                    <ion-buttons slot="end" style="display: block; margin: auto">
                      <ion-button
                        (click)="confirmPasswordField.type === 'password' ? confirmPasswordField.type = 'text' : confirmPasswordField.type = 'password'">
                        <ion-icon *ngIf="confirmPasswordField.type === 'password'" slot="icon-only" name="eye-off-outline"
                                  color="tertiary"></ion-icon>
                        <ion-icon *ngIf="confirmPasswordField.type === 'text'" slot="icon-only" name="eye-outline"
                                  color="tertiary"></ion-icon>
                      </ion-button>
                    </ion-buttons>
                    <ion-note *ngIf="ctrlConfirmPassword.touched && ctrlConfirmPassword.hasError('required')" slot="error">Conferma password obbligatoria</ion-note>
                    <ion-note *ngIf="!ctrlConfirmPassword.hasError('required') && ctrlConfirmPassword.hasError('mustMatch')" slot="error">Le password non combaciano
                    </ion-note>
                  </ion-item>

                  <!-- BUTTONS -->
                  <ion-grid>
                    <ion-row class="ion-justify-content-center ion-align-items-center ion-margin-top">
                      <ion-col size-sm="12" size-xl="6" size-md="8">
                        <ion-row class="ion-justify-content-center">
                          <ion-button [disabled]="this.recoverPasswordForm.invalid"
                            color="primary" (click)="changePassword()" class="full-w-percent">AGGIORNA PASSWORD
                          </ion-button>
                        </ion-row>
                      </ion-col>
                    </ion-row>
                  </ion-grid>
                </ion-col>
              </ion-row>
            </ion-grid>
          </ion-card>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
</ion-content>
