export enum PathEnum {
  PAGES = '/pages',
  LOGIN = '/login',
  // HOME = '/pages/home',
  HOME = '/pages/home',
  REGISTER = '/register',
  PROFILE = '/pages/profile',
  LOGOUT = '', //non c'è una page di logout
  RECOVER_PASSWORD = '/update-password',
  SCORE = '/pages/score',
  WIZARD = '/pages/wizard',
  AUTO = '/pages/auto',
  DETTAGLIO_SCORE = '/pages/dettaglio-score',
  DETTAGLIO_SCORE_WIZARD = '/pages/dettaglio-score-wizard',
  DETTAGLIO_SCORE_AUTO = '/pages/dettaglio-auto-score'
}

export enum IconMenuEnum {
  LOGIN = 'log-in-outline',
  HOME = 'home-outline',
  REGISTER = 'person-add-outline',
  PROFILE = 'person-circle-outline',
  LOGOUT = 'log-out-outline',
}

export enum LabelMenuEnum {
  LOGIN = 'ACCEDI',
  HOME = 'HOME',
  REGISTER = 'REGISTRATI',
  PROFILE = 'PROFILO',
  LOGOUT = 'LOG OUT',
  SCORE = 'SCORE',
  WIZARD = 'WIZARD',
  AUTO = 'AUTO',
}


export enum ReferenceImage {
  SCORE = 'assets/images/icona_menu_score.png',
  WIZARD = 'assets/images/icona_menu_wizard.png',
  AUTO = 'assets/images/icona_menu_auto.png',
}
