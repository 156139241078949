<div class="ion-padding">
  <div class="legal col-md-12">
    <h3>I nostri Servizi e la Sua privacy</h3>
    <!--Tabella informativa-->
    <div>
      <p><span>HEALTH&amp;LIFE</span> e i suoi
        supplementi sono un
        insieme di Servizi di
        informazione tecnica in medicina on line erogati attraverso
        internet
        e alcune app: usiamo i Suoi dati e quelli della Sue attività
        all’interno dei nostri Servizi per migliorare i contenuti
        offerti e
        rendere più efficaci le nostre strutture, legga in breve cosa
        facciamo.
      </p>
      <div>
        <div style="overflow-x:auto">
          <table border="1" cellpadding="0" cellspacing="0"
                 style="width: 100%; border-collapse: collapse; border: none;">
            <tbody>
            <tr>
              <td valign="top" width="137"
                  style="width: 102.45pt; border: 1pt solid white; background:#009bc3; padding: 0in 1.4pt;">
                <p><strong>FINALITÀ</strong></p>
              </td>
              <td valign="top" width="169" class="fr-selected-cell"
                  style="width: 127pt; border-top: 1pt solid white; border-bottom: 1pt solid white; border-right: none; background:#009bc3; padding: 0in 1.4pt;">
                <p><strong>PERCHÉ</strong></p>
              </td>
              <td valign="top" width="165"
                  style="width: 123.9pt; border-top: 1pt solid white; border-bottom: 1pt solid white; border-right: none; background:#009bc3; padding: 0in 1.4pt;">
                <p><strong>PER QUANTO TEMPO LI TRATTIAMO</strong></p>
              </td>
              <td valign="top" width="164"
                  style="width: 123.25pt; border: 1pt solid white; background:#009bc3; padding: 0in 1.4pt;">
                <p><strong>CON CHI LI CONDIVIDIAMO</strong></p>
              </td>
            </tr>
            <tr>
              <td valign="top" width="137"
                  style="width: 102.45pt; border-top: none; border-right: 1pt solid white; border-bottom: 1pt solid white; border-left: 1pt solid white; border-image: initial; background:#009bc3; padding: 0in 1.4pt;">
                <p><strong>Funzionamento del Servizio<strong>, delle app e
                  assistenza</strong></strong></p><strong></strong>
              </td>
              <td valign="top" width="169"
                  style="width: 127pt; border-top: none; border-left: none; border-bottom: 1pt solid white; border-right: 1pt solid white; background: rgb(180, 198, 231); padding: 0in 1.4pt;">
                <p>
                  Per assicurarci che tutti i nostri Utilizzatori siano
                  professionisti sanitari.
                </p>
              </td>
              <td valign="top" width="165"
                  style="width: 123.9pt; border-top: none; border-left: none; border-bottom: 1pt solid white; border-right: 1pt solid white; background: rgb(180, 198, 231); padding: 0in 1.4pt;">
                <p>
                  Per tutto il tempo della Sua iscrizione ai nostri Servizi.
                </p>
              </td>
              <td valign="top" width="164"
                  style="width: 123.25pt; border-top: none; border-left: none; border-bottom: 1pt solid white; border-right: 1pt solid white; background: rgb(180, 198, 231); padding: 0in 1.4pt;">
                <p>
                  HEALTH&amp;LIFE e le aziende incaricate di collaborare
                  all’erogazione del Servizio
                </p>
              </td>
            </tr>
            <tr>
              <td valign="top" width="137"
                  style="width: 102.45pt; border-top: none; border-right: 1pt solid white; border-bottom: 1pt solid white; border-left: 1pt solid white; border-image: initial; background:#009bc3; padding: 0in 1.4pt;">
                <p><strong>Funzionamento del Servizio, delle app e assistenza</strong></p>
              </td>
              <td valign="top" width="169"
                  style="width: 127pt; border-top: none; border-left: none; border-bottom: 1pt solid white; border-right: 1pt solid white; background: rgb(217, 226, 243); padding: 0in 1.4pt;">
                <p>
                  Per consentirle l’accesso semplificato ad altri Servizi che
                  richiedono un login e/o l’identificazione dell’Utilizzatore.
                </p>
              </td>
              <td valign="top" width="165"
                  style="width: 123.9pt; border-top: none; border-left: none; border-bottom: 1pt solid white; border-right: 1pt solid white; background: rgb(217, 226, 243); padding: 0in 1.4pt;">
                <p>
                  Per tutto il tempo della Sua iscrizione ai nostri Servizi.
                </p>
              </td>
              <td valign="top" width="164"
                  style="width: 123.25pt; border-top: none; border-left: none; border-bottom: 1pt solid white; border-right: 1pt solid white; background: rgb(217, 226, 243); padding: 0in 1.4pt;">
                <p>
                  HEALTH&amp;LIFE e le aziende incaricate di collaborare
                  all’erogazione del Servizio, con Clinical Forum S.r.l. per
                  il Servizio FaD.
                </p>
              </td>
            </tr>
            <tr>
              <td valign="top" width="137"
                  style="width: 102.45pt; border-top: none; border-right: 1pt solid white; border-bottom: 1pt solid white; border-left: 1pt solid white; border-image: initial; background:#009bc3; padding: 0in 1.4pt;">
                <p><strong>Funzionamento del Servizio, delle app e assistenza</strong></p>
              </td>
              <td valign="top" width="169"
                  style="width: 127pt; border-top: none; border-left: none; border-bottom: 1pt solid white; border-right: 1pt solid white; background: rgb(180, 198, 231); padding: 0in 1.4pt;">
                <p>
                  Assistiamo gli Utilizzatori che avessero delle difficoltà
                  con i nostri Servizi.
                </p>
              </td>
              <td valign="top" width="165"
                  style="width: 123.9pt; border-top: none; border-left: none; border-bottom: 1pt solid white; border-right: 1pt solid white; background: rgb(180, 198, 231); padding: 0in 1.4pt;">
                <p>
                  Per tutto il tempo della Sua iscrizione ai nostri Servizi.
                </p>
              </td>
              <td valign="top" width="164"
                  style="width: 123.25pt; border-top: none; border-left: none; border-bottom: 1pt solid white; border-right: 1pt solid white; background: rgb(180, 198, 231); padding: 0in 1.4pt;">
                <p>
                  HEALTH&amp;LIFE e le aziende incaricate di collaborare
                  all’erogazione del Servizio
                </p>
              </td>
            </tr>
            <tr>
              <td valign="top" width="137"
                  style="width: 102.45pt; border-top: none; border-right: 1pt solid white; border-bottom: 1pt solid white; border-left: 1pt solid white; border-image: initial; background:#009bc3; padding: 0in 1.4pt;">
                <p><strong>Per rispondere ai nostri obblighi legali e per difendere
                  i nostri diritti</strong></p>
              </td>
              <td valign="top" width="169"
                  style="width: 127pt; border-top: none; border-left: none; border-bottom: 1pt solid white; border-right: 1pt solid white; background: rgb(217, 226, 243); padding: 0in 1.4pt;">
                <p>
                  Per tutto il tempo della Sua iscrizione ai nostri Servizi o,
                  eventualmente, per il maggior tempo cui fossimo legalmente
                  tenuti o autorizzati.
                </p>
              </td>
              <td valign="top" width="165"
                  style="width: 123.9pt; border-top: none; border-left: none; border-bottom: 1pt solid white; border-right: 1pt solid white; background: rgb(217, 226, 243); padding: 0in 1.4pt;">
                <p>
                  Per rispondere ai nostri obblighi legali e per difendere i
                  nostri diritti
                </p>
              </td>
              <td valign="top" width="164"
                  style="width: 123.25pt; border-top: none; border-left: none; border-bottom: 1pt solid white; border-right: 1pt solid white; background: rgb(217, 226, 243); padding: 0in 1.4pt;">
                <p>
                  HEALTH&amp;LIFE e le aziende incaricate di collaborare
                  all’erogazione del Servizio
                </p>
              </td>
            </tr>
            <tr>
              <td valign="top" width="137"
                  style="width: 102.45pt; border-top: none; border-right: 1pt solid white; border-bottom: 1pt solid white; border-left: 1pt solid white; border-image: initial; background:#009bc3; padding: 0in 1.4pt;">
                <p><strong>Statistiche</strong></p>
              </td>
              <td valign="top" width="169"
                  style="width: 127pt; border-top: none; border-left: none; border-bottom: 1pt solid white; border-right: 1pt solid white; background: rgb(180, 198, 231); padding: 0in 1.4pt;">
                <p>
                  Per elaborare statistiche aggregate sulla composizione dei
                  nostri iscritti.
                </p>
              </td>
              <td valign="top" width="165"
                  style="width: 123.9pt; border-top: none; border-left: none; border-bottom: 1pt solid white; border-right: 1pt solid white; background: rgb(180, 198, 231); padding: 0in 1.4pt;">
                <p>
                  Per tutto il tempo della Sua iscrizione ai nostri Servizi,
                  in seguito saranno anonimizzati.
                </p>
              </td>
              <td valign="top" width="164"
                  style="width: 123.25pt; border-top: none; border-left: none; border-bottom: 1pt solid white; border-right: 1pt solid white; background: rgb(180, 198, 231); padding: 0in 1.4pt;">
                <p>
                  Potremmo condividere i dati aggregati (che quindi non La
                  identificano) con lo sponsor dei nostri Servizi e con
                  soggetti terzi.
                </p>
              </td>
            </tr>
            <tr>
              <td valign="top" width="137"
                  style="width: 102.45pt; border-top: none; border-right: 1pt solid white; border-bottom: 1pt solid white; border-left: 1pt solid white; border-image: initial; background:#009bc3; padding: 0in 1.4pt;">
                <p><strong>Statistiche</strong></p>
              </td>
              <td valign="top" width="169"
                  style="width: 127pt; border-top: none; border-left: none; border-bottom: 1pt solid white; border-right: 1pt solid white; background: rgb(217, 226, 243); padding: 0in 1.4pt;">
                <p>
                  Per elaborare statistiche aggregate sull’utilizzo dei
                  Servizi e delle app dei nostri Servizi.
                </p>
              </td>
              <td valign="top" width="165"
                  style="width: 123.9pt; border-top: none; border-left: none; border-bottom: 1pt solid white; border-right: 1pt solid white; background: rgb(217, 226, 243); padding: 0in 1.4pt;">
                <p>
                  Per tutto il tempo della Sua iscrizione ai nostri Servizi,
                  in seguito saranno anonimizzati.
                </p>
              </td>
              <td valign="top" width="164"
                  style="width: 123.25pt; border-top: none; border-left: none; border-bottom: 1pt solid white; border-right: 1pt solid white; background: rgb(217, 226, 243); padding: 0in 1.4pt;">
                <p>
                  Potremmo condividere i dati aggregati (che quindi non La
                  identificano) con gli eventuali sponsor dei nostri Servizi e
                  altri soggetti terzi.
                </p>
              </td>
            </tr>
            <tr>
              <td valign="top" width="137"
                  style="width: 102.45pt; border-top: none; border-right: 1pt solid white; border-bottom: 1pt solid white; border-left: 1pt solid white; border-image: initial; background:#009bc3; padding: 0in 1.4pt;">
                <p><strong>Comunicazione</strong></p>
              </td>
              <td valign="top" width="169"
                  style="width: 127pt; border-top: none; border-left: none; border-bottom: 1pt solid white; border-right: 1pt solid white; background: rgb(180, 198, 231); padding: 0in 1.4pt;">
                <p>
                  Le inviamo e-mail o push-notification anche personalizzate
                  per annunciare nuovi contenuti e dare altre notizie
                  significative sul Servizio.
                </p>
              </td>
              <td valign="top" width="165"
                  style="width: 123.9pt; border-top: none; border-left: none; border-bottom: 1pt solid white; border-right: 1pt solid white; background: rgb(180, 198, 231); padding: 0in 1.4pt;">
                <p>
                  Per tutto il tempo della Sua iscrizione ai nostri Servizi.
                </p>
              </td>
              <td valign="top" width="164"
                  style="width: 123.25pt; border-top: none; border-left: none; border-bottom: 1pt solid white; border-right: 1pt solid white; background: rgb(180, 198, 231); padding: 0in 1.4pt;">
                <p>
                  HEALTH&amp;LIFE e le aziende incaricate di collaborare
                  all’erogazione del Servizio. Al fine di limitare le
                  comunicazioni a lei dirette, i dati potranno inoltre essere
                  condivisi con i nostri sponsor e partner commerciali, a tale
                  scopo nominati responsabili del trattamento, per coordinare
                  le nostre attività di promozione con quelle condotte
                  autonomamente da questi soggetti.
                </p>
              </td>
            </tr>
            <tr>
              <td valign="top" width="137"
                  style="width: 102.45pt; border-top: none; border-right: 1pt solid white; border-bottom: 1pt solid white; border-left: 1pt solid white; border-image: initial; background:#009bc3; padding: 0in 1.4pt;">
                <p><strong>Comunicazione</strong></p>
              </td>
              <td valign="top" width="169"
                  style="width: 127pt; border-top: none; border-left: none; border-bottom: 1pt solid white; border-right: 1pt solid white; background: rgb(217, 226, 243); padding: 0in 1.4pt;">
                <p>
                  Attività di comunicazione su prodotti e attività di sponsor
                  e di sponsor e partner commerciali.
                </p>
              </td>
              <td valign="top" width="165"
                  style="width: 123.9pt; border-top: none; border-left: none; border-bottom: 1pt solid white; border-right: 1pt solid white; background: rgb(217, 226, 243); padding: 0in 1.4pt;">
                <p>
                  Per tutto il tempo della Sua iscrizione ai nostri Servizi,
                  in seguito saranno anonimizzati.
                </p>
              </td>
              <td valign="top" width="164"
                  style="width: 123.25pt; border-top: none; border-left: none; border-bottom: 1pt solid white; border-right: 1pt solid white; background: rgb(217, 226, 243); padding: 0in 1.4pt;">
                <p>
                  HEALTH&amp;LIFE e le aziende incaricate di collaborare
                  all’erogazione del Servizio o alla conduzione di questa
                  specifica attività.
                </p>
              </td>
            </tr>
            <tr>
              <td valign="top" width="137"
                  style="width: 102.45pt; border-top: none; border-right: 1pt solid white; border-bottom: 1pt solid white; border-left: 1pt solid white; border-image: initial; background:#009bc3; padding: 0in 1.4pt;">
                <p><strong>Miglioramento dell’esperienza di utilizzo</strong></p>
              </td>
              <td valign="top" width="169"
                  style="width: 127pt; border-top: none; border-left: none; border-bottom: 1pt solid white; border-right: 1pt solid white; background: rgb(180, 198, 231); padding: 0in 1.4pt;">
                <p>
                  Per offrire contenuti ritenuti di Suo interesse sulla base
                  di precedenti attività sui nostri Servizi o di Sue scelte
                  (cd. profilazione).
                </p>
              </td>
              <td valign="top" width="165"
                  style="width: 123.9pt; border-top: none; border-left: none; border-bottom: 1pt solid white; border-right: 1pt solid white; background: rgb(180, 198, 231); padding: 0in 1.4pt;">
                <p>
                  Per tutto il tempo della Sua iscrizione ai nostri Servizi.
                </p>
              </td>
              <td valign="top" width="164"
                  style="width: 123.25pt; border-top: none; border-left: none; border-bottom: 1pt solid white; border-right: 1pt solid white; background: rgb(180, 198, 231); padding: 0in 1.4pt;">
                <p>
                  HEALTH&amp;LIFE e le aziende incaricate di collaborare
                  all’erogazione del Servizio.
                </p>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>

      <!--Box informativa integrale-->
      <div class="legal-info pt-2">
        <p>
          Può leggere l’informativa integrale, che è compresa nel box
          seguente, dopo le condizioni d’uso del Servizio.
        </p>
      </div>
    </div>
  </div>
</div>
<div class="ion-padding">
  <div class="legal col-md-12">
    <p>La preghiamo inoltre di leggere le condizioni d’uso del Servizio:</p>
    <div id="gdprDocument" class="legal-container">
      <div class="boxTitle">
        <h1>Informazioni legali, condizioni e termini del Servizio</h1>
        <p><span class="font-weight-bold">HEALTH&amp;LIFE</span>
          La ringrazia per avere visitato il sito o per avere installato una
          delle app dei nostri Servizi e La prega di voler prestare particolare
          attenzione a queste informazioni, che costituiscono le condizioni
          d’uso del sito applicabili a ogni visita al sito e che il
          Visitatore/Lettore/Utilizzatore (Lei, nel caso concreto) accetta
          esplicitamente in maniera integrale. Se ritenesse di non poter o non
          voler accettare tali condizioni, La preghiamo di abbandonare il sito.
          Se sta utilizzando una app per il calcolo di score clinici o
          contenente un indice di linee guida od ogni altra app che presenti
          questa informativa La preghiamo di prestare particolare attenzione al
          punto 8 lettera E di questa comunicazione.
        </p>
        <h2>1. Premessa Generale:</h2>
        <ol type="a">
          <li>
            Il Servizio www.OncoTalk.it è un supplemento al
            periodico
            HEALTH&amp;LIFE (<a href="http://journal.health-life.it">http://journal.health-life.it</a>) in attesa di
            registrazione presso il tribunale di Milano.
          </li>
          <li>
            Il Servizio è promosso da HEALTH&amp;LIFE ed è rivolto agli
            Utilizzatori
            residenti sul territorio italiano secondo le condizioni
            d’uso e le
            norme di diritto italiano applicabili.
          </li>
          <li>
            In considerazione della possibilità che Lei si colleghi
            da un luogo
            diverso dal territorio della Repubblica Italiana, Lei
            riconosce come
            unico applicabile l’ordinamento giuridico della
            Repubblica Italiana,
            rinunciando irrevocabilmente a ogni altro ordinamento
            eventualmente
            applicabile.
          </li>
          <li>
            Lei dichiara inoltre di avere le conoscenze linguistiche
            sufficienti
            per la piena comprensione di questo documento, a
            prescindere dalla
            Sua lingua madre.
          </li>
          <li>
            Con l’offerta del Servizio OncoTalk, HEALTH&amp;LIFE non
            intende
            concludere alcun rapporto contrattuale di alcuna natura,
            in nome o
            interesse proprio o di quello dei soggetti che per conto
            di
            HEALTH&amp;LIFE operano nell’erogazione del Servizio o degli
            eventuali
            sponsor dello stesso, pertanto nessun contratto può
            essere desunto
            dalla distribuzione del Servizio o dall’accesso e/o
            utilizzo dello
            stesso.
          </li>
          <li>
            È a carico dell’Utilizzatore del Servizio ogni costo
            relativo
            all’acquisto di dispositivi eventualmente necessari per
            usare il
            Servizio, così come quelli relativi alle connessioni
            necessarie per
            l’accesso a Internet. Le ricordiamo che lo scambio di
            dati tramite
            internet potrebbe portare a costi specifici in base al
            piano di dati
            dell’operatore che sta utilizzando; consigliamo di
            evitare di
            utilizzare il trasferimento dei dati in mobilità durante
            il roaming
            e di preferire i collegamenti in Wi-Fi.
          </li>
        </ol>
        <h2>2. Definizioni:</h2>
        <ol type="a">
          <li><span class="font-weight-bold">Il</span> Servizio /
            <span class="font-weight-bold">i</span> Servizi: :
            questa
            definizione comprende i Servizi di informazione tecnica
            erogati da
            HEALTH&amp;LIFE S.r.l. attraverso i domini
            <a href="www.health-life.it">www.health-life.it</a>,
            <a href="www.oncotalk.it">www.oncotalk.it</a>, ogni
            sottodominio e
            ogni applicazione per dispositivo mobile collegata al
            Servizio che
            mostrino questa informativa.
          </li>
          <li>
            ll nome di dominio www.OncoTalk.it, o gli altri dei vari
            Servizi, il
            sito così come può essere visitato da chi vi accede, i
            Servizi di
            supporto all’erogazione del sito, l’infrastruttura
            tecnologica, i
            contenuti, i Servizi collaterali erogati dal sito, ogni
            diritto di
            proprietà intellettuale. Il nome di dominio
            www.OncoTalk.it o il
            nome del Servizio vanno considerati come indicanti anche
            l’applicazione (cd. “App”) per smart device sviluppata e
            distribuita
            per offrire l’accesso in mobilità al Servizio.
          </li>
          <li>
            App dei nostri Servizi: la definizione comprende, oltre
            alla app che
            replica i contenuti del Servizio per i dispositivi
            mobili, anche
            ogni app che eroghi funzioni integrate nel Servizio e/o
            che utilizzi
            il sistema di autenticazione condivisa del Servizio, e
            ogni altra
            app che presenti questa informativa.
          </li>
          <li>
            Editore:
            <span class="font-weight-bold">HEALTH&amp;LIFE</span> S.r.l.
            con sede
            legale in via Santa Sofia 22 Milano e operativa in viale
            Gorizia 22,
            20144 Milano partita IVA 07382900962, e-mail
            staff@cnc-group.it.
            Azienda parte di CnC GROUP. Le aziende parte di CnC
            GROUP sono
            sottoposte all’attività di direzione e coordinamento di
            CnC S.r.l.
          </li>
          <li>
            Responsabile esterno del trattamento: soggetto esterno a
            HEALTH&amp;LIFE
            cui i dati possono essere comunicati per operazioni
            specifiche.
          </li>
          <li>Sponsor: soggetti che acquistano spazi pubblicitari.
          </li>
          <li>
            Partner: soggetti che conducono iniziative commerciali
            congiunte con
            HEALTH&amp;LIFE.
          </li>
          <li>
            Lettore/Utilizzatore/Visitatore: ogni persona che accede
            al Servizio
            e/o ai suoi contenuti.
          </li>
        </ol>
        <h2>3. Esclusione di garanzie:</h2>
        <ol type="a">
          <li>
            Il Servizio è offerto ed erogato da HEALTH&amp;LIFE nello
            stato in cui esso
            si trova, senza garanzia alcuna, né esplicita né
            implicita circa la
            durata, la qualità, i contenuti, l’esattezza di questi,
            l’utilizzabilità, il funzionamento, la continuità
            nell’erogazione, la
            sicurezza e il suo aggiornamento; di conseguenza
            l’accesso al sito e il
            suo utilizzo avvengono sotto l’esclusiva responsabilità
            dell’Utilizzatore.
          </li>
          <li>
            La pubblicazione del presente Servizio non implica
            assunzione di obbligo
            all’erogazione dell’accesso o al rilascio dei dati di
            autenticazione. La
            valutazione di ogni eventuale richiesta di accesso al
            Servizio è a
            discrezione di HEALTH&amp;LIFE che potrà accoglierla o meno.
          </li>
          <li>
            Nessuna garanzia è parimenti fornita relativamente alle
            risorse offerte
            da terzi terzi cui, a seguito di collegamento con questo
            Servizio,
            l’Utilizzatore acceda. In particolare, nessuna garanzia
            è offerta, e
            nessuna responsabilità di HEALTH&amp;LIFE o dei soggetti che
            per suo conto
            e/o interesse operano nell’amministrazione di questo
            Servizio, sia degli
            sponsor del Servizio, è affermabile, per il contenuto
            dei dati
            distribuiti dai terzi o tratti da fonti esterne, per i
            quali soli
            responsabili saranno i fornitori dei dati.
          </li>
          <li>
            Con l’accesso al sito l’Utilizzatore riconosce nella
            maniera più ampia e
            senza eccezioni che in nessun caso, alcuno tra i
            soggetti coinvolti
            nella realizzazione, distribuzione, sponsorizzazione del
            sito potrà
            essere ritenuto responsabile per perdite di profitti o
            per danni,
            diretti e indiretti, di ogni genere (compresa la perdita
            e/o il
            danneggiamento di dati), derivanti dall’uso delle
            informazioni ottenute
            attraverso il Servizio o dal ricorso alle funzioni di
            questo.
          </li>
          <li>HEALTH&amp;LIFE si riserva espressamente il diritto
            unilaterale, e senza
            necessità di comunicazione alcuna, di limitare
            parzialmente o
            completamente le possibilità di accesso di un
            Utilizzatore e anche di
            disattivare o cancellare l’account di accesso (username
            e password degli
            Utilizzatori), l’Utilizzatore rinuncia fin d’ora nella
            maniera più ampia
            e senza eccezione alcuna a ritenere HEALTH&amp;LIFE in alcun
            modo
            responsabile per la cancellazione dell’accesso al
            Servizio, e tutto ciò
            a favore anche di terzi.
          </li>
        </ol>
        <h2>4. Eventuali servizi particolari erogati attraverso il sito</h2>
        <ol type="a">
          <li>
            Qualora il Servizio offrisse una funzione di
            prenotazione (funzione
            “workshop”, “posti riservati” o simili) per la
            partecipazione a
            incontri, Le ricordiamo la necessità di presentarsi
            all’ingresso
            dell’evento almeno 15 minuti prima dell’orario d’inizio.
            Oltre questo
            termine, potrà essere consentito l’accesso secondo
            l’ordine di
            presentazione, e quindi potrebbe non essere più
            disponibile un posto per
            Lei. In assenza di conferma e-mail da parte nostra,
            nessuna garanzia è
            offerta e nessuna responsabilità è assunta per il
            funzionamento del
            sistema di prenotazione. HEALTH&amp;LIFE segnala e
            l’Utilizzatore accetta
            senza riserve la possibilità che, per diversi motivi,
            l’Organizzatore
            possa sospendere, spostare, cancellare l’evento
            previsto, ridurre il
            numero di partecipanti ammessi, selezionare le
            richieste, modificare le
            attribuzioni a eventi specifici, senza per questo essere
            responsabile
            verso i richiedenti.
          </li>
          <li>
            Qualora il Servizio offrisse uno strumento di
            condivisione per materiali
            degli Utilizzatori che questi possono inserire nel
            Servizio al fine di
            renderli disponibili agli altri Utilizzatori o
            trasmetterli ad altri
            soggetti, l’utilizzo di tale strumento implica
            l’accettazione piena
            delle seguenti regole:
            <ol type="b">
              <li>
                l’Utilizzatore offre piena liberatoria sulla
                titolarità di ogni
                contenuto inviato, per il quale quindi ha il
                diritto di
                utilizzo, pubblicazione, duplicazione;
              </li>
              <li>
                l’Utilizzatore rinuncia a opporre all’Editore
                del sito qualunque
                rivendicazione circa l’uso che soggetti terzi
                collegatisi al
                sito potrebbero fare dei contenuti così
                pubblicati;
              </li>
              <li>
                l’Utilizzatore autorizza in via irrevocabile
                HEALTH&amp;LIFE alla
                pubblicazione dei contenuti attraverso il sito,
                senza che
                HEALTH&amp;LIFE sia tenuta ad adottare tecnologie di
                alcun tipo per
                impedire la loro duplicazione, copia o modifica;
              </li>
              <li>
                l’Utilizzatore rinuncia espressamente a ogni
                compenso o
                indennità comunque definibile, che non sia stata
                specificamente
                ed espressamente pattuita in forma scritta prima
                della
                condivisione da parte dell’Utilizzatore;
              </li>
            </ol>
          </li>
          <li>
            Qualora il sito offrisse funzioni di archiviazione on
            line (“web
            storage”, “web FTP”), l’Utilizzatore rinuncia in maniera
            specifica a
            qualsiasi garanzia di funzionamento, continuità,
            accessibilità di tali
            funzioni, altresì, l’Utilizzatore si impegna a non
            fruirne per usi
            contrari al diritto italiano. Tali funzioni potranno
            essere interrotte
            in qualsiasi momento senza necessità di preavviso
            alcuno.
          </li>
        </ol>
        <h2>5. Copyright e diritti di proprietà intellettuale e industriale</h2>
        <ol type="a">
          <li>
            Ogni parte del Servizio è coperta da copyright a favore
            di HEALTH&amp;LIFE
            e/o dei soggetti eventualmente titolari dei diritti di
            proprietà
            intellettuale, sfruttamento economico, e altri
            assimilabili.
          </li>
          <li>
            Ogni riferimento a marchi aziendali, o nomi di prodotto
            non implica la
            titolarità di diritti rispetto a questi.
          </li>
          <li>
            L’utilizzo del Servizio è consentito esclusivamente per
            ragioni
            personali e di documentazione scientifica non collegata
            ad attività
            commerciali. L’eventuale riproduzione dei contenuti
            dovrà riportare
            l’avvertenza "© <b>HEALTH&amp;LIFE</b> <a href="www.cnc-group.it">www.cnc-group.it</a>"
            su ogni pagina pertinente. Ogni riproduzione del
            Servizio e/o dei suoi
            contenuti per scopi diversi da quelli appena indicati
            potrà avvenire
            solo previa autorizzazione scritta di HEALTH&amp;LIFE
            S.r.l.. Ogni
            riproduzione dei contenuti di proprietà di terzi deve
            essere considerata
            riservata.
          </li>
          <li>
            Nulla, nel contenuto del Servizio, può essere
            interpretato come
            concessione di licenza o diritto alcuno senza
            l’esplicito permesso
            scritto di HEALTH&amp;LIFE o dei terzi proprietari dei
            diritti.
          </li>
          <li>
            L’accesso al Servizio, e alle funzioni eventualmente
            raggiungibili da
            questo e l’utilizzo dei contenuti eventualmente
            disponibili dovranno
            sempre avvenire nel rispetto delle presenti condizioni
            d’uso. Le
            ricordiamo che la violazione di tali condizioni può
            essere fonte anche
            di gravi conseguenze penali.
          </li>
          <li>
            Qualora l’Utilizzatore invii informazioni di qualsiasi
            natura al
            Servizio, tale invio è esplicitamente considerato
            dall’Utilizzatore come
            autorizzazione illimitata all’utilizzo di tali
            informazioni, alla loro
            archiviazione, elaborazione, modifica, pubblicazione e
            riproduzione con
            ogni mezzo e senza limiti di tempo o spazio, e con
            l’esplicita rinuncia
            da parte dell’Utilizzatore a qualsiasi compenso
            economico, comunque
            definibile, che non sia stato specificamente ed
            espressamente pattuito
            in forma scritta prima dell’invio da parte
            dell’Utilizzatore. Le
            informazioni inviate in forma fisica non danno il
            diritto alla
            restituzione dei supporti su cui sono riprodotte.
          </li>
        </ol>
        <h2>6. Sicurezza dei collegamenti</h2>
        <ol type="a">
          <li>
            Il Servizio cui Lei accede è erogato attraverso il
            collegamento a
            strumenti informatici remoti rispetto al Suo
            computer/altro dispositivo
            elettronico.
          </li>
          <li>
            La comunicazione tra dispositivi elettronici, sia
            attraverso lo scambio
            di supporti di memoria, sia mediante la connessione
            attraverso rete
            telematica, può comportare alcuni rischi in materia di
            sicurezza dei
            dati e degli stessi dispositivi.
          </li>
          <li>
            Durante l’accesso a informazioni presenti su Internet,
            il Suo
            dispositivo elettronico potrebbe registrare, all’interno
            dei propri
            supporti di memoria, programmi (cosiddetti virus
            informatici)
            deliberatamente sviluppati da terzi al fine di ridurre
            le prestazioni
            del Suo dispositivo e/o danneggiare i dati da Lei
            registrati e/o
            danneggiare lo stesso dispositivo. Contro
            quest’eventualità si ricorre
            usualmente all’installazione di programmi di protezione.
            La invitiamo a
            voler valutare questa possibilità, anche in relazione
            alle risorse di
            sistema del Suo dispositivo.
          </li>
          <li>
            A prescindere dalla condotta illecita di terzi, esiste
            comunque la
            possibilità che l’accesso a contenuti disponibili in
            Internet porti a
            una diminuzione delle risorse di sistema del Suo
            dispositivo o a
            conflitti (incompatibilità) con programmi già
            installati. Ciò può
            accadere sia in occasione di attività di mera
            consultazione di dati, sia
            qualora Lei decidesse di registrare (“scaricare”,
            “download”) sul Suo
            dispositivo file di dati o interi programmi. La
            invitiamo, pertanto, a
            considerare queste possibilità alla luce delle
            specifiche del Suo
            dispositivo e a consultare, qualora disponibili, le
            istruzioni dei
            fornitori dei dati o programmi.
          </li>
        </ol>
        <h2>7. Dati personali di accesso al Servizio e identificazione degli Utilizzatori</h2>
        <ol type="a">
          <li>
            Le ricordiamo che l’ID utente (username) e la parola
            chiave (password,
            codice alfanumerico di autorizzazione all’accesso)
            eventualmente forniti
            sono personali e non cedibili. Ogni responsabilità per
            la violazione di
            tale vincolo così come per lo smarrimento di tali
            informazioni che non
            sia stato comunicato prontamente al servizio di
            assistenza è
            esclusivamente a carico dell’Utilizzatore.
          </li>
          <li>
            Ai fini della protezione degli Utilizzatori e del
            rispetto delle norme
            italiane applicabili, HEALTH&amp;LIFE implementa sistemi di
            registrazione e
            autenticazione finalizzati a segmentare il pubblico e a
            concedere
            l’accesso a contenuti qualificati esclusivamente agli
            Utilizzatori che
            rientrano nelle condizioni previste dall’ordinamento
            (età, professione e
            simili). In tutti questi casi HEALTH&amp;LIFE raccoglie i
            dati sul
            presupposto della buona fede dell’Utilizzatore che, con
            la compilazione
            e l’invio dei moduli di registrazione dichiara, sotto la
            propria
            responsabilità ai sensi delle disposizioni civili e
            penali applicabili
            in tema di falsità in dichiarazioni e per gli eventuali
            danni che tali
            condotte causassero, che le informazioni fornite sono
            vere, complete e
            corrette. HEALTH&amp;LIFE si riserva ogni azione di tutela
            contro gli
            Utilizzatori che accedessero fornendo una falsa
            rappresentazione della
            propria identità e/o condizione personale.
          </li>
        </ol>
        <h2>8. Contenuti medico - scientifici eventualmente forniti o raggiungibili dal sito</h2>
        <ol type="a">
          <li>
            La predisposizione dei collegamenti verso risorse
            esterne al Servizio
            non implica, a nessun titolo, la prestazione da parte di
            HEALTH&amp;LIFE di
            garanzie implicite o esplicite circa i contenuti
            reperibili in tali
            risorse.
          </li>
          <li>
            HEALTH&amp;LIFE Le ricorda, in particolare, che le eventuali
            indicazioni di
            prescrizione per specialità farmaceutiche, che
            potrebbero essere
            reperite attraverso il collegamento con risorse esterne,
            debbono
            ritenersi limitate al territorio per il quale sono state
            previste e
            dalla cui Autorità sanitaria sono state autorizzate. Per
            l’utilizzo
            delle suddette specialità farmaceutiche sul territorio
            italiano,
            dovranno in ogni caso seguirsi le indicazioni
            autorizzate e applicabili
            per il territorio italiano.
          </li>
          <li>
            <b>NOTA SPECIALE PER L’ACCESSO DI PUBBLICO NON
              MEDICO:</b> quando
            l’accesso è protetto da password o richiede una
            registrazione, il
            Servizio è destinato esclusivamente a un pubblico di
            professionisti
            sanitari, ed è pertanto interdetto l’accesso a chiunque
            non rientri in
            questa definizione: l’eventuale accesso alle aree
            riservate da parte di
            chi non sia un professionista sanitario avviene in
            violazione della
            volontà di HEALTH&amp;LIFE e della Legge ed è suscettibile
            di responsabilità
            civili e penali. Nessuna responsabilità è assunta da
            HEALTH&amp;LIFE per
            eventuali inesattezze o imprecisioni d’alcun genere che
            potessero essere
            pubblicate. In nessun caso, le informazioni pubblicate
            potranno essere
            considerate come invito all’utilizzo di particolari
            specialità
            farmaceutiche, <b>chi ritenesse di riconoscere nei
            contenuti consultati
            condizioni a sé comuni, deve ricorrere al proprio Medico
            curante per
            ogni decisione terapeutica, che è esclusivamente da
            affidarsi
            all’apprezzamento di professionisti competenti in
            materia.</b> Le
            informazioni fornite riflettono lo stato delle
            conoscenze mediche al
            momento della preparazione. Nondimeno, per la
            possibilità comunque
            esistente di errori in qualsiasi fase di realizzazione e
            per il continuo
            avanzamento della Scienza Medica, non è possibile
            garantire che le
            informazioni contenute siano attuali, accurate e
            complete in ogni loro
            parte, né può essere assunta alcuna responsabilità per
            errori od
            omissioni o per i risultati di decisioni assunte sulla
            base delle
            informazioni riprodotte, per tale motivo il Lettore è
            invitato a
            verificare l’attualità e correttezza delle informazioni
            riportate.
          </li>
          <li>
            <b>NOTA SPECIALE PER L’ACCESSO DI PROFESSIONISTI
              SANITARI.</b> I
            contenuti scientifici messi a disposizione da questo
            sito sono destinati
            esclusivamente a un pubblico di professionisti sanitari
            per esclusive
            finalità di documentazione professionale e senza
            obiettivi pubblicitari.
            Nessuna informazione contenuta dovrebbe essere
            considerata come invito
            alla prescrizione o assunzione di specialità
            farmaceutiche, per le quali
            ciascun Medico dovrà fare riferimento alle indicazioni
            di prescrizione
            vigenti. Le informazioni contenute riflettono lo stato
            delle conoscenze
            mediche al momento della preparazione. Nondimeno, per la
            possibilità
            comunque esistente di errori in qualsiasi fase di
            realizzazione e il
            continuo avanzamento della Scienza Medica, non è
            possibile garantire che
            le informazioni contenute siano attuali, accurate e
            complete in ogni
            loro parte, né può essere assunta alcuna responsabilità
            per errori od
            omissioni o per i risultati di decisioni assunte sulla
            base delle
            informazioni riprodotte, per tale motivo il Lettore è
            invitato a
            verificare l’attualità e correttezza delle informazioni
            riportate.
          </li>
          <li>
            <b>NOTA SPECIALE SUI CALCOLATORI DI SCORE CLINICI E
              SUGLI INDICI DI
              LINEE GUIDA:</b> i calcolatori di score clinici e
            gli indici di
            linee guida sono stati predisposti con la massima
            attenzione, attingendo
            alla più qualificata letteratura scientifica e
            verificati prima del
            rilascio. Nonostante ogni attenzione, è sempre possibile
            che errori
            siano presenti o che il software non sia aggiornato
            rispetto
            all’evoluzione delle conoscenze mediche, per questo
            motivo, oltre a
            quanto già scritto sopra, La invitiamo a non prendere
            alcuna decisione
            medica esclusivamente sulla base delle informazioni di
            questo o di altri
            software, ma a valutare attentamente le opzioni
            diagnostiche e
            terapeutiche alla luce delle conoscenze scientifiche e
            della Sua
            esperienza clinica.
          </li>
          <li>
            <b>NOTA SPECIALE SULLE SPONSORIZZAZIONI.</b> Questo
            Servizio, così come
            singoli contenuti o funzioni, potrebbe prevedere la
            sponsorizzazione
            commerciale da parte di operatori economici
            potenzialmente portatori di
            conflitti di interesse rispetto alle informazioni
            pubblicate. La
            pubblicazione di banner o altri materiali di
            comunicazione pubblicitaria
            relativi a prodotti o attività o altro dei soggetti
            sponsor non implica
            in alcun modo invito all’utilizzo di particolari
            specialità
            farmaceutiche né affermazione di adeguatezza dei
            prodotti e/o attività
            oggetto della comunicazione pubblicitaria rispetto ai
            contenuti della
            pagina mostrata dallo schermo, né l’approvazione di
            HEALTH&amp;LIFE di tali
            prodotti, attività e/o dei contenuti e/o degli Autori
            per i prodotti o
            le attività dello sponsor, così come non implica che lo
            sponsor offra
            garanzie sui contenuti e/o funzioni del Servizio
            pubblicato da
            HEALTH&amp;LIFE , né riconosca i contenuti come validi o
            presti qualsivoglia
            garanzia su contenuti e/o funzioni del Servizio.
          </li>
        </ol>
        <h2>9. Nota speciale per l’accesso in aree riservate per Utilizzatori specifici</h2>
        <p>
          I contenuti delle aree riservate di questo sito sono
          destinati all’esclusivo
          utilizzo interno degli Utilizzatori autorizzati.
          L’Utilizzatore autorizzato
          all’accesso ad aree riservata dichiara, qualora in tali aree
          vi siano
          contenuti sottoposti a regole particolari di utilizzo e/o
          distribuzione e/o
          pubblicazione e/o riproduzione, di essere a conoscenza di
          tali regole e di
          vincolarsi a rispettare ogni normativa applicabile
        </p>
        <h2>10. Responsabilità personale</h2>
        <p>
          L’Utilizzatore ha presente che l’uso di ogni strumento che
          gli consenta di
          inserire dati sulla rete internet, è regolato dai principi
          generali di legge
          in tema di responsabilità per la sicurezza e segretezza
          delle comunicazioni
          elettroniche, per il contenuto di dichiarazioni e/o per la
          tutela del
          diritto d’autore, senza esclusione di ogni altra norma
          specifica applicabile
        </p>
        <h2>11. Norme di chiusura e Foro esclusivo</h2>
        <ol type="a">
          <li>
            HEALTH&amp;LIFE si riserva il diritto di cambiare i termini
            e le condizioni
            d’uso in semplicemente attraverso la pubblicazione delle
            nuove
            condizioni nel sito. La continuazione dell’utilizzo del
            Servizio dopo la
            modifica delle condizioni d’uso varrà come accettazione
            delle nuove
            condizioni di utilizzo. HEALTH&amp;LIFE si riserva altresì
            di esercitare il
            diritto di porre fine al Servizio e/o all’erogazione a
            favore
            dell’Utilizzatore, senza per questo essere soggetta ad
            alcuna richiesta
            ad alcun titolo da parte dell’Utilizzatore.
          </li>
          <li>
            Qualsiasi utilizzo del sito in violazione delle presenti
            condizioni è
            rigorosamente vietato e una violazione di diritti di
            proprietà
            intellettuale che sarà perseguita nella più vasta
            estensione consentita
            dall’ordinamento italiano e da quelli altrimenti
            applicabili.
          </li>
          <li>
            Ogni controversia relativa od occasionata dal presente
            accordo, sarà
            devoluta alla esclusiva del Tribunale di Milano.
          </li>
        </ol>
        <h1><b>Il nostro impegno per la Sua riservatezza</b> (informativa ai sensi del Regolamento Generale UE sulla
          Protezione dei Dati 2016/679 – GDPR)</h1>
        <h2>1) Chi siamo</h2>
        <p>I siti web e le app dei nostri Servizi sono pubblicati da
          HEALTH&amp;LIFE S.r.l.
          con sede legale in via Santa Sofia 22 Milano e operativa in
          viale Gorizia
          22, 20144 Milano partita IVA 07382900962, e-mail
          staff@cnc-group.it: si può
          rivolgere a questi recapiti per ogni adempimento e domanda
          in merito ai Suoi
          dati personali.</p>
        <h2>2) Perché raccogliamo i suoi dati</h2>
        <p>HEALTH&amp;LIFE Le offre l’accesso a un insieme di Servizi
          integrati di
          documentazione e informazione professionale erogati
          attraverso sistemi
          informatici: alcuni dati sono necessari per far funzionare i
          Servizi, altri
          per comunicare con Lei, altri ancora per rendere possibile
          l’offerta dei
          nostri Servizi grazie alle sponsorizzazioni pubblicitarie,
          di seguito Le
          spieghiamo puntualmente come trattiamo i Suoi dati.</p>
        <h2>3) Quali dati trattiamo </h2>
        <p>HEALTH&amp;LIFE Le offre un sistema di autenticazione unificata
          ai propri Servizi
          grazie al quale potrà accedere ai vari Servizi (siti
          internet, app,
          software) usando un solo nome utente e una sola password.
          Trattiamo i dati che Lei ci ha fornito all’atto della Sua
          registrazione ai
          nostri Servizi e i dati che derivano dal Suo utilizzo delle
          nostre app e dei
          nostri siti; questi ultimi dati in particolare possono
          derivare da sorgenti
          diverse, come le registrazioni sulla Sua interazione con i
          nostri server e i
          cookie scaricati dai nostri Servizi sui suoi dispositivi (se
          vuole
          approfondire questo argomento, abbiamo preparato una nota
          per Lei in fondo a
          questo documento).
        </p>
        <h2>4) Come usiamo i dati relativi alla Sua iscrizione e utilizzo dei nostri Servizi?</h2> <br>
        <p><b>Finalità di erogare e garantire il funzionamento del
          Servizio, delle app e
          l’attività di assistenza.</b></p>
        <p>L’accesso a questo Servizio e agli altri collegati è limitato
          ai
          Professionisti della Sanità, per questo dobbiamo
          identificare ogni
          Utilizzatore, e assicurarci che solo i Professionisti
          Sanitari usino i
          nostri Servizi.
          Inoltre, il nostro sistema di autenticazione è predisposto
          per permetterle
          permette anche di usare servizi esterni, erogati da altre
          piattaforme (per
          esempio servizi da Formazione a Distanza erogati da provider
          ECM nostri
          partner), senza bisogno di registrarsi o autenticarsi
          nuovamente: questo
          viene fatto condividendo alcune informazioni con queste
          piattaforme.
          Infine, assistiamo gli Utilizzatori che avessero delle
          difficoltà con i
          nostri Servizi, e usiamo i dati (questi e i dati di utilizzo
          dei Servizi)
          per “controllare quel che è successo”, ma anche per
          ricontattare gli
          Utilizzatori.
          Usiamo questi dati per tutto il tempo della Sua iscrizione
          ai nostri Servizi
          e, nel caso Lei si disiscrivesse, li anonimizzeremo.
          Questi dati potranno essere trattati da HEALTH&amp;LIFE e da
          altre aziende
          incaricate di collaborare all’erogazione del Servizio:
          alcune aziende
          interne a CnC GROUP (il gruppo di cui fa parte HEALTH&amp;LIFE)
          e alcuni partner
          esterni.
          Come Le abbiamo indicato, queste informazioni sono
          essenziali per il
          funzionamento degli strumenti indicati, perciò il Suo
          consenso è necessario
          per tutti gli utilizzi legati a questa finalità: senza il
          Suo consenso non
          potremo erogare il Servizio a Suo favore.</p> <br>
        <p><b>Finalità di protezione dei diritti e assolvimento di
          obblighi di
          legge.</b></p>
        <p>A questi fini, conserveremo i Suoi dati per tutto il tempo
          della Sua
          iscrizione ai nostri Servizi o, eventualmente, per il
          maggior tempo cui
          fossimo legalmente tenuti, o fosse consentito dal diritto
          applicabile.
          Questi dati potranno essere trattati da HEALTH&amp;LIFE e da
          altre aziende
          incaricate di collaborare all’erogazione del Servizio:
          alcune aziende
          interne a CnC GROUP (il gruppo di cui fa parte HEALTH&amp;LIFE)
          e alcuni partner
          esterni.</p> <br>
        <p><b>Finalità di elaborare statistiche sulla composizione dei
          nostri
          iscritti.</b></p>
        <p>Questa finalità è per noi molto importante: ci permette di
          capire cosa
          potrebbe interessare ai nostri Utilizzatori e di migliorare
          l’offerta di
          contenuti e Servizi in funzione del loro profilo
          professionale; inoltre,
          attraverso i dati aggregati, possiamo promuovere le
          sponsorizzazioni sui
          nostri Servizi e offrire il nostro Servizio gratuitamente
          alla comunità
          medica italiana.
          Potremmo condividere i dati aggregati (che quindi non La
          identificano) con
          gli sponsor dei nostri Servizi e con soggetti terzi.
          Tratteremo i Suoi dati per tutto il tempo della Sua
          iscrizione ai nostri
          Servizi, in seguito saranno anonimizzati ed elaborati come
          dati anonimi.
          Come Le abbiamo indicato, queste informazioni sono
          essenziali per il
          funzionamento degli strumenti indicati, perciò il Suo
          consenso è necessario
          per tutti gli utilizzi legati a questa finalità: senza il
          Suo consenso non
          potremo erogare il Servizio a Suo favore.
        </p> <br>
        <p><b>Finalità di inviare comunicazioni dirette sui nostri
          Servizi.</b></p>
        <p>I nostri Utilizzatori apprezzano l’aggiornamento costante dei
          nostri Servizi,
          per questo inviamo e-mail o push-notification per annunciare
          nuovi contenuti
          e dare altre notizie significative, anche personalizzando i
          contenuti e
          anche adattandoli al dispositivo utilizzato. Non cediamo i
          dati dei nostri
          Utilizzatori. Il consenso è necessario al fine
          dell’erogazione del Servizio.
          Tratteremo i Suoi dati in questo modo per tutto il tempo
          della Sua
          iscrizione ai nostri Servizi.
          Questi dati potranno essere trattati da HEALTH&amp;LIFE e da
          altre aziende
          incaricate di collaborare all’erogazione del Servizio:
          alcune aziende
          interne a CnC GROUP (il gruppo di cui fa parte HEALTH&amp;LIFE)
          e alcuni partner
          esterni. Le segnaliamo che lo sponsor dei nostri Servizi
          promuove i
          contenuti dei nostri Servizi al proprio database: per
          ridurre la possibilità
          di disturbarla con comunicazioni sovrapposte lo abbiamo
          incaricato di
          incrociare alcuni dati tra il proprio e il nostro database,
          a tal fine è
          definito “responsabile esterno del trattamento” e li
          utilizzerà nei limiti
          delle istruzioni ricevute.
        </p> <br>
        <p><b>Finalità di inviare comunicazioni su prodotti e attività
          di sponsor e
          partner commerciali.</b></p>
        <p>I nostri sponsor o partner commerciali sono usualmente leader
          globali nella
          salute e conducono proprie attività di comunicazione
          scientifica indirizzate
          ai Professionisti della Sanità come Lei: la Sua
          autorizzazione facoltativa
          Le consentirà di ricevere informazioni professionali utili
          dallo sponsor o
          dal partner. Tratteremo i Suoi dati per tutto il tempo della
          Sua iscrizione
          ai nostri Servizi, in seguito saranno anonimizzati. I Suoi
          dati potranno
          essere trattati da HEALTH&amp;LIFE e da altre aziende incaricate
          di collaborare
          all’erogazione del Servizio o alla conduzione di questa
          specifica attività:
          alcune aziende interne a CnC GROUP (il gruppo di cui fa
          parte HEALTH&amp;LIFE) e
          alcuni partner esterni. I Suoi dati non saranno ceduti.
        </p> <br>
        <p><b>Finalità di miglioramento dell’esperienza di utilizzo</b></p>
        <p>Si tratta della cosiddetta “profilazione”, uno strumento per
          offrire
          contenuti ritenuti di Suo interesse sulla base di precedenti
          attività sui
          nostri Servizi o di Sue scelte. In questo modo possiamo
          semplificare e
          migliorare il Suo utilizzo dei nostri Servizi offrendole
          prima di tutto
          contenuti coerenti con la sua specializzazione e/o
          precedenti ricerche o
          indicazioni di gradimento espresse: è uno strumento a Suo
          favore, che può
          accettare o no, ma che speriamo apprezzerà.
          Questi dati potranno essere trattati da HEALTH&amp;LIFE e da
          altre aziende
          incaricate di collaborare all’erogazione del Servizio:
          alcune aziende
          interne a CnC GROUP (il gruppo di cui fa parte HEALTH&amp;LIFE)
          e alcuni partner
          esterni.
          Tratteremo i Suoi dati per tutto il tempo della Sua
          iscrizione ai nostri
          Servizi, in seguito saranno anonimizzati ed elaborati come
          dati anonimi.
        </p>
        <h2>5) Dove conserviamo i Suoi dati?</h2>
        <p>I nostri server sono in Italia e in altri luoghi dell’Unione
          Europea; qualora
          i Suoi dati potessero essere trattati fuori dall’Unione
          Europea, ci
          impegniamo a informarla e a fare in modo che gli stessi
          ricevano un grado di
          protezione almeno conforme a quanto previsto dalle regole
          dell’Unione
          Europea.</p>
        <h2>6) Chi sono gli altri soggetti che possono accedere ai miei dati?</h2>
        <p>Questi soggetti sono i cosiddetti “responsabili esterni del
          trattamento”,
          ossia organizzazioni che trattano i dati per conto di
          HEALTH&amp;LIFE e nei
          limiti delle indicazioni ricevute.
          Innanzitutto, le aziende parte di CnC GROUP. HEALTH&amp;LIFE è
          una società
          soggetta a direzione e coordinamento da parte di CnC S.r.l.
          e fa parte del
          gruppo di società denominato “CnC GROUP”, caratterizzato da
          integrazione
          delle funzioni e dei cicli produttivi; pertanto parti delle
          attività
          necessarie per il funzionamento di questo Servizio sono
          condotte attraverso
          il ricorso ad altre società appartenenti al medesimo CnC
          GROUP, siamo a Sua
          disposizione per ulteriori chiarimenti.
          Le ricordiamo che, nell’ambito di specifici accordi con
          alcuni sponsor e
          partner commerciali, questi potrebbero essere incaricati di
          incrociare
          alcuni dati per evitare che, nella propria autonoma attività
          di promozione,
          si sovrappongano alle attività di comunicazione diretta da
          parte di
          HEALTH&amp;LIFE.
          Infine, Amazon Web Services, le cui “cloud farm” nell’Unione
          Europea
          ospitano le infrastrutture informatiche che utilizziamo per
          i servizio web.
        </p>
        <h2>7) Quali diritti ho in relazione al trattamento dei miei dati?</h2>
        <p>In ogni momento, Lei potrà esercitare i diritti ex Art. 15
          Regolamento UE
          679/2016, e in particolare quelli di accesso, cancellazione,
          limitazione e
          opposizione al trattamento, portabilità dei dati, revoca del
          consenso senza
          che ciò pregiudichi la liceità del trattamento condotto sino
          alla revoca
          rivolgendosi a HEALTH&amp;LIFE S.r.l., Servizio privacy, viale
          Gorizia 22 Milano
          20144, fax 0258105318, e-mail staff@cnc-group.it che è allo
          scopo
          identificata come titolare del trattamento. Lei ha il
          diritto inoltre di
          proporre reclamo all’Autorità garante per la protezione dei
          dati personali
          (www.garanteprivacy.it) per lamentare una violazione della
          disciplina in
          materia di protezione dei dati personali e di richiedere una
          verifica.</p>
        <h2>8) È possibile che altri soggetti accedano ai miei dati?</h2>
        <p>Su Internet è molto facile passare da una risorsa all’altra,
          e quindi Lei
          potrebbe “uscire” dal nostro Servizio seguendo un link a una
          risorsa
          esterna: Le ricordiamo che, qualora durante l’utilizzo di
          siti raggiungibili
          dal nostro Servizio, Le fossero richiesti dati personali,
          tali richieste
          potrebbero essere assoggettate a discipline diverse da
          quelle vigenti sul
          territorio italiano. HEALTH&amp;LIFE non potrà essere
          considerata responsabile
          (e Lei riconosce espressamente questo fatto) per i dati da
          Lei forniti a
          soggetti terzi, rispetto alla cui diligenza nel trattare i
          Suoi dati e nel
          rispettare le eventuali leggi locali sulla privacy,
          HEALTH&amp;LIFE non offre né
          può offrire alcuna garanzia, neppure implicita.
          In particolare: le app
          Se ha installato una della app dei nostri Servizi su un Suo
          smart device
          (smartphone, tablet), ricordi che, oltre ai dati trattati da
          noi, è
          possibile che l’utilizzo generi informazioni trattate dal
          produttore del
          sistema operativo o dal fabbricante del Suo dispositivo o di
          altre app: si
          tratta di operazioni indipendenti da noi, che però Lei può
          controllare
          attraverso le impostazioni del Suo dispositivo circa le
          informazioni che
          condivide con questi soggetti.
        </p>
        <h2>9) Una nota finale su cookie e su dati raccolti in forma automatica</h2>
        <p>I server che distribuiscono i nostri Servizi potrebbero
          registrare
          automaticamente alcune informazioni: 1. il dominio e il
          server dal quale il
          Suo computer o altro dispositivo accede a Internet, 2.
          l’indirizzo Internet
          del sito web dal quale Lei si è collegato a un Servizio
          internet, 3. la data
          e l’ora della Sua visita, la durata della Sua visita al
          sito, le pagine del
          sito da Lei consultate e la durata di tali consultazioni, 4.
          il Suo
          “indirizzo IP”, 5. alcune caratteristiche del Suo computer o
          altro
          dispositivo di accesso a Internet, nonché il tipo di sistema
          operativo
          utilizzato, il tipo di programma di consultazione dei
          contenuti Internet
          (es. MS Internet Explorer, Firefox, Opera, Chrome ecc.)
          eventuali “plug-in”
          disponibili; si tratta di informazioni che i web server
          raccolgono di
          consueto, e che quindi anche i sistemi che erogano i nostri
          Servizi possono
          raccogliere.</p>
        <p>Al di là della "cookie policy", Le vorremmo fornire qualche
          informazione in
          più sui cookie, dei piccoli file di testo che permettono di
          rendere più
          facile la Sua navigazione, raccogliere dati statistici e
          definire il Suo
          profilo di utilizzo così da fornirle contenuti sempre più
          aderenti ai Suoi
          interessi:</p>
        <ul>
          <li><b>cookie tecnici proprietari</b> di navigazione
            anonimi, finalizzati al
            trasferimento di dati necessari a consentire la corretta
            navigazione tra
            le pagine del sito, la trasmissione di dati che
            l’Utilizzatore decide
            esplicitamente di trasmettere, l’utilizzo delle
            funzionalità e l’accesso
            a contenuti protetti;
          </li>
          <li><b>cookie analitici anonimi di terza parte</b> (Google
            Analytics)
            finalizzati a ricostruire le modalità di utilizzo di
            questo sito, per
            ottimizzare e migliorare il sito, rendendolo sempre
            interessante e
            rilevante per gli utenti;
          </li>
          <li><b>cookie di profilazione proprietari</b> la
            profilazione consiste nel
            tenere memoria dei Suoi accessi, dei contenuti
            consultati, della
            attività svolte, la lettura di messaggi inviati dai
            nostri Servizi
            attraverso push notification, e-mail o altri strumenti
            nonché le date di
            queste azioni e nell’elaborare questi dati per
            ricostruire la Sua
            personale esperienza di navigazione e utilizzo e
            fornirle risorse e
            contenuti sempre più personalizzati.
          </li>
        </ul>
        <p>La preghiamo di ricordare che visitando un sito web
          raggiungibile dai
          collegamenti predisposti da un nostro Servizio il Suo
          dispositivo potrebbe
          ricevere cookie sia dal sito visitato (“cookie
          proprietari”), sia da siti
          gestiti da altre organizzazioni (“cookie di terze parti”):
          può consultare la
          privacy policy e la cookie policy all’interno del sito da
          Lei visitato.
          Infine, Le ricordiamo che <b>può modificare o disattivare le
            preferenze dei
            cookie del Suo browser:</b> entri nelle impostazioni del
          browser
          (Internet Explorer, Safari, Firefox, Chrome ecc..) e
          stabilisca quali sono i
          cookie che desidera ricevere e quali no, alcuni di questi
          programmi Le
          possono consentire anche di definire impostazioni
          differenziate per i cookie
          “proprietari” e per quelli di “terze parti”.
          Per sapere dove può trovare suddette impostazioni, utilizzi
          la funzione
          “Help” (o “Aiuto”, “Manuale”, “Guida”, “Istruzioni”) del
          browser. I seguenti
          link mostrano come è possibile impostare le preferenze sui
          browser e
          dispositivi utilizzati più comunemente:
        </p>
        <ul>
          <li><b>Android by Google:</b> per informazioni specifiche
            per il suo modello
            di smart device, Le suggeriamo di usare un motore di
            ricerca (es.
            Google) e di scrivere nel box di ricerca: impostazione
            cookie seguito
            dalla marca e modello di dispositivo da Lei usato
          </li>
          <li><b>Apple iPhone, iPad, iPod touch: </b> <a target="_blanck"
                                                         href="https://support.apple.com/it-it/HT201265"> Apple Mac
            OS</a></li>
          <li><b>Apple Mac, browser Safari:</b> <a target="_blanck"
                                                   href="https://support.apple.com/kb/PH21411?viewlocale=it_IT&amp;locale=it_IT">
            Apple Mac OS</a></li>
          <li><b>Firefox: </b> <a target="_blanck" href="https://support.mozilla.org/it/kb/Gestione%20dei%20cookie">
            Firefox</a></li>
          <li><b>Google Chrome: </b> <a target="_blanck" href="https://support.google.com/chrome/answer/95647?hl=it">
            Google</a></li>
          <li><b>Microsoft Edge (Windows 10): </b> <a target="_blanck"
                                                      href="http://windows.microsoft.com/it-it/windows-10/edge-privacy-faq">
            Microsoft Edge</a></li>
          <li><b>Microsoft Internet Explorer versioni 8-11: </b> <a target="_blanck" href="http://windows.microsoft.com/it-it/internet-explorer/delete-manage-cookies#ie=ie-11
                                                                (per i sistemi operativi Windows 7, Windows 8.1, Windows
                                                                10)"> Microsoft Internet Explorer</a></li>
          <li><b>Opera: </b> <a target="_blanck" href="http://help.opera.com/Windows/10.00/it/cookies.html">
            Opera </a></li>
        </ul>
        <p>Le ricordiamo che la scelta di bloccare la ricezione dei
          cookie, potrebbe
          compromettere o impedire il funzionamento del nostro
          Servizio o di funzioni
          specifiche all’interno del Servizio; la disabilitazione dei
          cookie “di terze
          parti” non pregiudica in alcun modo la navigabilità.
          Può trovare informazioni generali sui cookie alle pagine
          www.youronlinechoices.com/it, www.allaboutcookies.org (in
          inglese),
          www.cookiechoices.org, it.wikipedia.org e
          www.garanteprivacy.it.
        </p>
      </div>
    </div>
  </div>
</div> <br>
