import {Injectable} from "@angular/core";
import {LocalStorageService} from "./local-storage.service";
import {catchError, from, map, Observable, of, switchMap, tap, throwError, timeout, timer, zip} from "rxjs";
import {ModalController, NavController} from "@ionic/angular";
import {PathEnum} from "../shared/enums/path-enum";
import {
  ConfigurationControllerService, Configurazione,
  LoginResponse,
  UserControllerService
} from "../../api-clients/generated/services";
import {get} from "lodash";
import {ActivatedRoute, Router} from "@angular/router";
import {Auth} from "../shared/interfaces/auth";
import {ConnectionStatus} from "@capacitor/network/dist/esm/definitions";
import {Network} from "@capacitor/network";
import {NetworkService} from "./network.service";
import {ProfileManagerService} from "./profile-manager.service";
import {RequestTypesEnum} from "./manage-error.service";
import {ModalConfiguration} from "../shared/interfaces/modal-configuration-interface";
import {Validators} from "@angular/forms";
import {ModalRoleEnum} from "../shared/enums/modal-enum";
import {NotificationService} from "./notification.service";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class AppInitService {

  networkStatus: ConnectionStatus | undefined;
  offlineErrorMessage = 'Attenzione, connessione scarsa o assente. I dati visualizzati potrebbero non essere aggiornati.'
  offlineErrorMessageForAutologin = 'Attenzione, connessione scarsa o assente.'


  constructor(
    private localStorageService: LocalStorageService,
    private modalCtrl: ModalController,
    public navCtrl: NavController,
    private userController: UserControllerService,
    private route: ActivatedRoute,
    private router: Router,
    private networkService: NetworkService,
    private configurationControllerService: ConfigurationControllerService,
    private notificationService: NotificationService) {
  }

  init() {
    return new Observable((subscriber) => {
      zip([
        this.init$(),
        timer(3000)
      ]).pipe(
        map(value => value[0]),
      ).subscribe({
        next: value => {
          if(!!this.localStorageService.getAccessToken()) {
            this.navCtrl.navigateRoot(PathEnum.HOME);
          }
          if (value === RequestTypesEnum.CONFIGURAZIONE_APP) {
            this.openModalForAppNotAbilitata();
          }
          subscriber.complete();
        },
        error: err => {
          subscriber.complete();
        }
      });
    })
  }

  async getNetWorkStatus() {
    // this.networkStatus = await Network.getStatus();
    this.networkService.networkStatus = await Network.getStatus();
    this.networkStatus  = this.networkService.networkStatus;
    console.log('AAA network status', this.networkStatus);
    return !this.networkStatus?.connected;
  }

  isRecoverPasswordPath(): boolean {
    return location.pathname === PathEnum.RECOVER_PASSWORD
  }

  getQueryParamTokePresent(): string | null {
    return new URL(location.href).searchParams.get('token');
  }

  init$() {
    return from(this.getNetWorkStatus()).pipe(
      tap((isOffline) =>
        this.networkService.sendStatusChangeMsg(
          {
            connected: !isOffline,
            connectionType: 'unknown'
          })
      ),
      switchMap((isOffline) => this.initAfterCheckNetwork$(isOffline)),
    )
  }

  initAfterCheckNetwork$(isOffline: boolean) {
    if (!isOffline) {
      // Controllo la configurazione
      return this.configurationControllerService.getConfiguration(environment.version).pipe(
        catchError( () => this.localStorageService.getConfiguration$()),
        switchMap((config) => this.localStorageService.setPreferenceConfigurazione$(config)),
        map((value: Configurazione) => value.dettaglio?.appAbilitata),
        switchMap((appAbilitata) =>  {
        // se l'app è abilitata proseguo con il flusso
          if (appAbilitata) {
            // verifico se è il flusso di recupero password
            if (this.isRecoverPasswordPath()) {
              return this.localStorageService.cleanPreferenceToken$()
            } else {
              return this.autologin$().pipe(
                switchMap(() => this.setInfoUser$())
              )
            }
          } else {
            return of(RequestTypesEnum.CONFIGURAZIONE_APP)
          }
        })
      )
    } else {
      return this.offlineFlow$()
    }

  }

  autologin$(): Observable<LoginResponse | boolean> {
    if (!!this.getQueryParamTokePresent()) {
      return this.autologinFlow(this.getQueryParamTokePresent()!);
    } else {
      return of(false)
    }
  }

  autologinFlow(token: string) {
    return this.userController.autologintUser(token).pipe(
      timeout(5000),
      catchError((err) => {
          console.error(err);
          if( err.name === RequestTypesEnum.TIMEOUT || err.status === 504 ||
            (!this.networkService.networkStatus?.connected && !this.networkStatus?.connected)) {
            this.notificationService.openToast({message: this.offlineErrorMessageForAutologin, color: 'danger'});
            return throwError(() => err)
          } else {
            return throwError(() => err)
          }
        }
      ),
      switchMap((value) => this.localStorageService.setAuth$(value as Auth).pipe(
        map(() => value)
      ))
    )
  }

  setInfoUser$() {
    return this.getUserIfLoggedObservable$().pipe(
      switchMap((value) => {
        if (value) {
          return this.localStorageService.setPreferenceProfile$(value);
        } else {
          return of(false)
        }
      }),
    );
  }

  getUserIfLoggedObservable$(): Observable<any> {
    return this.localStorageService.getAccessToken$().pipe(
      switchMap((isLogged) => !!isLogged ?
        this.userController.getUser().pipe(
          timeout(5000),
          switchMap((user) => this.localStorageService.setPreferenceProfile$(user)),
          catchError((err) => {
              console.error(err);
              if (err.name === RequestTypesEnum.TIMEOUT || err.status === 504 ||
                (!this.networkService.networkStatus?.connected && !this.networkStatus?.connected)) {
                this.notificationService.openToast({message: this.offlineErrorMessage, color: 'danger'});
                return this.localStorageService.getProfile$();
              } else {
                return throwError(() => err);
              }
            }
          ),
        )
        : of(null)),
    )
  }

  offlineFlow$() {
    return this.localStorageService.getConfiguration$().pipe(
      map((value: Configurazione) => value.dettaglio?.appAbilitata),
      switchMap((appAbilitata) =>  {
        // se l'app è abilitata proseguo con il flusso
        if (appAbilitata) {
          // verifico se è il flusso di recupero password
          if (this.isRecoverPasswordPath()) {
            return this.localStorageService.cleanPreferenceToken$()
          } else {
            return this.autologin$().pipe(
              switchMap(() => this.setInfoUser$()),
            )
          }
        } else {
          return of(RequestTypesEnum.CONFIGURAZIONE_APP)
        }
      }
    ))
  }

  openModalForAppNotAbilitata() {
    const redirectModalConfiguration: ModalConfiguration = {
      backdropDismiss: false,
      form: {
        'confirm': [true, Validators.required]
      },
      text: {
        modal_title: 'ATTENZIONE',
        modal_message: 'Attenzione, l\'applicazione non è attualmente disponibile, si prega di riprovare.',
      },
      conditions: {
        hidden_form: true,
        confirm_button: false,
        cancel_button: false
      },
      cssClass: 'generic-modal'
    }
    this.notificationService.openModal(redirectModalConfiguration, 'ModalForProfessione');
  }
}
