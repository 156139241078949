import {Injectable} from '@angular/core';
import {
  ActionPerformed,
  PushNotificationSchema,
  PushNotifications,
  Token,
} from '@capacitor/push-notifications';
import {ModalConfiguration} from "../shared/interfaces/modal-configuration-interface";
import {Validators} from "@angular/forms";
import {emailRegex} from "../shared/constants/regex";
import {NotificationService} from "./notification.service";

@Injectable({
  providedIn: 'root'
})
export class PushManagerService {

  constructor(private notificationService: NotificationService) {
  }

  inizializzaPush() {
    console.log('Initializing Push');

    this.requestPermissionAndRegisterToPushNotification();

  }

  requestPermissionAndRegisterToPushNotification() {
    PushNotifications.requestPermissions().then(result => {
      if (result.receive === 'granted') {
        // Register with Apple / Google to receive push via APNS/FCM
        console.log('GESTIONE PUSH CASO DI SUCCESSO');
        PushNotifications.register();
      } else {
        // Show some error
        console.log('GESTIONE PUSH CASO DI ERRORE');
      }
    });

    this.addListenerPushNotifications()
  }

  addListenerPushNotifications() {
    PushNotifications.addListener('registration', (token: Token) => {
      console.log('GESTIONE PUSH Push registration success, token: ' + token.value);
    });

    PushNotifications.addListener('registrationError', (error: any) => {
      console.log('Error on registration: ' + JSON.stringify(error));
    });

    PushNotifications.addListener(
      'pushNotificationReceived',
      (notification: PushNotificationSchema) => {
        console.log('Push received: ' + JSON.stringify(notification));
        let title= notification.data.title;
        let message= notification.data.message;
        if(!!message) {
          this.openPushModal(title, message);
        }
      },
    );

    PushNotifications.addListener(
      'pushNotificationActionPerformed',
      (notification: ActionPerformed) => {
        console.log('Push action performed: ' + JSON.stringify(notification));
        let title= notification.notification.data.title;
        let message= notification.notification.data.message;
        if(!!message) {
          this.openPushModal(title, message);
        }
      },
    );
  }

  openPushModal(title: string, message: string) {
    const pushConfiguration: ModalConfiguration = {
      form: {
        'push': [true]
      },
      text: {
        modal_title: !!title ? title : 'VTE Risk Calculator',
        modal_message: message,
        confirm_button: 'Chiudi',
      },
     conditions: {
        hidden_form: true,
        cancel_button: false,
     },
      cssClass: 'generic-modal'
    }
    this.notificationService.openModal(pushConfiguration);
  }

}
